import {DICTS} from "../../common/constants";
import {useGetList} from "react-admin";
import Resources from "../../Resources";

export const DictLoader = (props) => {
    if (DICTS.STORE_CURRENCY_SCALES_MAP == null || DICTS.STORE_CURRENCY_LABELS_MAP == null) {
        useGetList(Resources.DICT_CURRENCIES.name, {sort: {field: 'code', order: 'ASC'}}, {
            // staleTime: 1000 * 60 * 60,
            onSuccess: (data) => {
                DICTS.STORE_CURRENCY_SCALES_MAP = data.data.reduce((a, {code, scale}) => ({...a, [code]: scale}), {});
                DICTS.STORE_CURRENCY_LABELS_MAP = data.data.reduce((a, {code, name}) => ({...a, [code]: `${code} - ${name}`}), {});
            }
        });
    }

    return <></>;
}