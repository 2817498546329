import PropTypes from 'prop-types'
import React from "react";
import {SelectArrayInput, SelectInput, useChoicesContext} from "react-admin";

const SelectInputWithChoiceContext = (props) => {
    const {availableChoices} = useChoicesContext();
    const superOnChange = (event) => {
        if (props.onChange) {
            props.onChange(event, availableChoices)
        }
    }
    const {multipleChoice, alwaysOn, ...rest} = props;
    if(props.multipleChoice) {
        return <SelectArrayInput {...props} onChange={superOnChange}/>
    } else {
        // noinspection RequiredAttributes
        return <SelectInput {...rest} onChange={superOnChange}/>
    }
}

export default SelectInputWithChoiceContext;

SelectInputWithChoiceContext.propTypes = {
  multipleChoice: PropTypes.bool,
  alwaysOn: PropTypes.bool,
  onChange: PropTypes.func
}