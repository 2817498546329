import {ReferenceField, TextField, FunctionField} from "react-admin";
import * as React from "react";
import PropTypes from "prop-types";

const DictField = (props) => {
    return (
        <ReferenceField record={props.record} link={""} label={props.label} source={props.source}
                        sort={{...props.sort, ...{field: "code", order: "ASC"}}}
                        reference={props.reference}>
            <>
                {!props.optionText && <TextField source="name" />}
                {props.optionText && typeof props.optionText === 'string' && <TextField source={props.optionText} />}
                {props.optionText && typeof props.optionText === 'function' && <FunctionField render={props.optionText} />}
            </>
        </ReferenceField>
    );
}

DictField.propTypes = {
    record: PropTypes.object,
    sort: PropTypes.object,
    filter: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    reference: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    alwaysOn: PropTypes.bool,
    optionText: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
}

export default DictField;
