import React from "react";
import Resources from "../../Resources";
import {AutocompleteInput, ReferenceInput, useDataProvider, useInput} from "react-admin";
import PropTypes from 'prop-types';
import {AccountProvider} from "../dicts/AccountEnums";
import TextField from "@mui/material/TextField";
import {formatAmount} from "../../common/utils";
import {DictLoader} from "./DictLoader";

function formatLabel(record) {
    const balance = formatAmount(
        record.currency,
        record.balanceStr ? record.balanceStr : record.balance
    )

    return record.id
        + " " + (record.virtual ?  "Virtual" : "Non Virtual")
        + " - " + balance
        + (record.accountName ? " - " + record.accountName : "")
        + (record?.source?.accountProvider ? " " + AccountProvider[record?.source?.accountProvider]?.label : "")
        + (record?.source?.IBAN ? " IBAN: " + record.source.IBAN : "")
        + (record?.source?.wallet ? " Wallet: " + record.source.wallet : "")
}

export function AccountInput(props) {
    const dataProvider = useDataProvider();
    const {onChange, onBlur, ...rest} = props;
    const isRequired = props.required;
    const {
        field,
        fieldState: {isTouched, invalid, error},
        formState: {isSubmitted},

    } = useInput({
        onChange,
        onBlur,
        ...props,
    });

    return (
        <>
            <DictLoader/>
            <ReferenceInput label={props.label} source={props.source}
                            reference={Resources.ACCOUNTS.name} isRequired={isRequired}
                            filter={props.filter} allowEmpty>
                <AutocompleteInput disabled={props.disabled} fullWidth
                                   clearOnBlur={false}
                                   resettable
                                   clearAlwaysVisible
                                   label={props.label}
                                   isRequired={isRequired}
                                   optionText={(record) => formatLabel(record)}
                                   optionValue="id"
                                   matchSuggestion={props.matchOptionSuggession}
                                   onChange={(accountId) => {
                                       if (accountId && (props.setAccountCurrency || props.setAccountDetails)) {
                                           dataProvider.getOne(Resources.ACCOUNTS.name, {id: accountId})
                                               .then(({data}) => {
                                                   // console.log("data", data)
                                                   if (props.setAccountDetails) {
                                                       props.setAccountDetails(data);
                                                   }
                                                   if (props.setAccountCurrency) {
                                                       props.setAccountCurrency(data.currency);
                                                   }
                                               })
                                               .catch((err) => {
                                                   console.error("error in AccountInput.onChange when loading account details", err)
                                               })
                                       }
                                       if (props.onChange) {
                                           props.onChange(accountId);
                                       }
                                   }}
                                   filterToQuery={(searchText) => {
                                       const record = searchText.split(" ");
                                       return {idContains: encodeURI(record[0])}
                                   }
                                   }
                                   renderInput={(params) => (
                                       <TextField {...params} label={"Owner"} variant="filled"
                                                  error={(isTouched || isSubmitted) && invalid}
                                                  helperText={(isTouched || isSubmitted) && invalid ? error : ''}
                                                  required={isRequired}
                                                  {...rest} />
                                   )}
                />
            </ReferenceInput>
        </>
    );
}

AccountInput.propTypes = {
    disabled: PropTypes.bool,
    filter: PropTypes.object.isRequired,
    label: PropTypes.string,
    matchOptionSuggession: PropTypes.func,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    setAccountCurrency: PropTypes.func,
    setAccountDetails: PropTypes.func,
    source: PropTypes.string.isRequired
}
