import React from "react";
import {Create} from "react-admin";
import PersonsForm from "./PersonsForm";

function PersonsCreatePage(props) {
    return (
        <Create redirect="list">
            <PersonsForm />
        </Create>
    );
}

export default PersonsCreatePage;