import PropTypes from 'prop-types'
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button,
    FileField,
    FileInput,
    Form, useDataProvider, useNotify, useRefresh,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import SelectNewCreditorsGrid from "./SelectNewCreditorsGrid";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";

function AppendCreditorsDialog(props) {
    const {close} = props;
    const [creditorIds, setCreditorIds] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const {loanApplication} = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();


    const validateForm = (form) => {
        const errors = {};
        if(creditorIds.length === 0) {
            errors.creditorArray = "At least one creditor has to be selected";
        }
        return errors;
    }

    const submit = (data) => {
        setSubmitting(true);
        // console.log('onCapitalSourcingSubmit', data)
        dataProvider['doUpload'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.APPEND_CREDITORS.name,
            id: loanApplication.id,
            method: 'PUT',
            body: {creditorIds: creditorIds.toString()},
        }).then(({data}) => {
            if (data.success) {
                onSuccess(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
        });
    };

    function onSuccess(data) {
        // console.log("capitalSourcing.onSuccess", data)
        close();
        setSubmitting(false);
        notify(
            'New creditors have been granted to view the loan application brief data',
            {type: 'success', autoHideDuration: 2000}
        );
        refresh();
    };


    return (
        <Dialog onClose={close} open={props.isOpened} fullWidth maxWidth="md">
            <DialogTitle>Append Capital Partners (<LoanApplicationTitle  loanApplication={props.loanApplication} />)</DialogTitle>
            <Form onSubmit={submit} validate={validateForm}>
                <DialogContent>

                    <DialogContentText>
                        Please choose appropriate creditors to share the loan application with and press Submit.
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <SelectNewCreditorsGrid loanApplicationId={props.loanApplication.id}
                                                    loanAmount={loanApplication.loanAmount}
                                                    loanCurrency={loanApplication.currency}
                                                    sector={loanApplication.sectorCode}
                                                    country={loanApplication.rawdata.country}
                                                    borrowerType={loanApplication.borrowerTypeCode}
                                                    loanIrrTo={loanApplication.irrTo}
                                                    loanType={loanApplication.rawdata.loanType}
                                                    exceptExisting={true}
                                                    region={loanApplication.regionCode}
                                                    businessStage={loanApplication.businessStageCode}
                                                    loanStructure={loanApplication.loanStructureCode}
                                                    revenue={loanApplication.revenue}
                                                    ebitda={loanApplication.ebitda}
                                                    enterpriseValue={loanApplication.enterpriseValue}
                                                    useOfFunds={loanApplication.useOfFundsCode}
                                                    loanTerm={loanApplication.loanTerm}
                                                    creditorIds={creditorIds}
                                                    setCreditorIds={setCreditorIds}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={submitting || creditorIds.length === 0} label="Submit"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default AppendCreditorsDialog;


AppendCreditorsDialog.propTypes = {
  close: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  loanApplication: PropTypes.object.isRequired
}
