export const NDAStatus = {
    SIGNED: {
        code: "SIGNED", label: "Signed",
        desc: "NDA is signed by all sides"
    },
    REQUIRED: {
        code: "REQUIRED", label: "Required",
        desc: "NDA is not signed yet"
    },
    NOT_REQUIRED: {
        code: "NOT_REQUIRED", label: "Not required",
        desc: "NDA is not required"
    },
}