import {Datagrid, EditButton, ShowButton, TextField} from "react-admin";
import AuthenticatedBulkDeleteAction from "./AuthenticatedBulkDeleteAction";
import DictField from "./DictField";
import LegalEntityRelDetails from "./LegalEntityRelDetails";
import Resources from "../../Resources";

const LegalEntitiesDatagrid = (props) => {
    return (
        <Datagrid data-testid="legal-entities-list-grid" bulkActionButtons={<AuthenticatedBulkDeleteAction />} >
            <TextField source="id"/>
            <TextField source="fullCompanyName" label="Company Name"/>
            <TextField source="registrationNumber" label="Registration number"/>
            <TextField source="website" label="Website"/>
            <DictField source="legalFormCode" label="Legal Form" reference={Resources.DICT_LEGAL_FORMS.name}/>
            {props.withRelations && <LegalEntityRelDetails source="relationType" />}
            <EditButton/>
            <ShowButton/>
        </Datagrid>
    );
}

export default LegalEntitiesDatagrid;
