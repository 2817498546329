import {useRecordContext} from "react-admin";
import {FunctionField} from "ra-ui-materialui";
import {PartyRelations} from "../dicts/PartyPartyRelEnums";

function PersonRelDetails(props) {
    const record = useRecordContext();
    const source = props.source;
    let result = "";
    if(record[source] && record[source].length > 0) {
        for (let i = 0; i < record[source].length; i++) {
            if(i > 0) {
                result += ', ';
            }
            let needSeparator = false;
            if(record[source][i].relationType) {
                result += PartyRelations[record[source][i].relationType].label;
                needSeparator = true;
            }
            if(record[source][i].shareAmount) {
                result += "/ ownership%: " + record[source][i].shareAmount;
                needSeparator = true;
            }
            if(record[source][i].relationTitle) {

                result += (needSeparator ? "/" : "") + record[source][i].relationTitle
            }
        }
    }
    return (
        <FunctionField label="Relation" source={props.source} render={(record) => result} />
    );
}

export default PersonRelDetails;
