import PropTypes from 'prop-types'
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {Button, Form, TextInput, useDataProvider, useNotify} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Resources from "../../Resources";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";
import {onError} from "../../common/utils";

export function InactivationDialog(props) {

    const {close, record, isLoading, open} = props;
    const [submitting, setSubmitting] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    if (!record) {
        return <></>
    }

    const handleClose = (event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        close();
    }

    function onFormSubmit(data) {
        setSubmitting(true)

        dataProvider['doUpload'](Resources.LOAN_APPLICATIONS.name, {
            id: record.id,
            action: Resources.LOAN_APPLICATIONS.actions.INACTIVATE.name,
            method: 'PUT',
            body: data
        })
            .then(({data}) => {
                setSubmitting(false)

                if (data.success) {
                    notify(
                        'Loan application successfully inactivated',
                        {type: 'success', autoHideDuration: 2000}
                    );
                } else {
                    const msg =
                        data.errMessage
                            ? data.errMessage
                            : "System error";
                    onError({message: msg}, notify)
                }

                close()
            })
            .catch(error => {
                setSubmitting(false);
                onError(error, notify)
                close()
            });
    }

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
            <DialogTitle>Inactivation Dialog (<LoanApplicationTitle  loanApplication={record} />)</DialogTitle>
            <Form record={record} onSubmit={onFormSubmit}>
                <DialogContent>
                    <DialogContentText>
                        Please fill comment if required and inactivate
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <TextInput source="inactiveComment" fullWidth label="Comment"/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" label="Inactive" disabled={isLoading || submitting}/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

InactivationDialog.propTypes = {
    close: PropTypes.func,
    isLoading: PropTypes.bool,
    open: PropTypes.bool,
    record: PropTypes.object,
}
