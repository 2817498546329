export const ReportStatus = {
    WAITING: {
        code: "WAITING",
        label: "Waiting for execution agent",
    },
    EXECUTING: {
        code: "EXECUTING",
        label: "Still executing",
    },
    READY: {
        code: "READY",
        label: "Ready to view",
    },
    FAILED: {
        code: "FAILED",
        label: "Error"
    }
}

export const ReportFormat = {
    EXCEL: {
        code: "EXCEL",
        label: "Excel",
    },
/*
    PDF: {
        code: "PDF",
        label: "PDF Document",
    },
*/
}

export const ReportNames = {
    LoanBookReport: {
        code: "LoanBookReport"
    },
    LoanApplicationAuditReport: {
        code: "LoanApplicationAuditReport"
    },
    DataroomAuditReport: {
        code: "DataroomAuditReport"
    },
    DataroomAuditSummaryReport: {
        code: "DataroomAuditSummaryReport"
    }
}