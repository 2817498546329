import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {Button, Form, TextInput,} from "react-admin";
import DialogContentText from "@mui/material/DialogContentText";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";

function RejectLoanApplicationDialog(props) {
    const {close, record, open, submit} = props;

    const formValidate = (values) => {
        const errors = {};
        if (!values.internalRationale) {
            errors.internalRationale = 'Internal rationale is required';
        }
        return errors;
    };

    return (
        <Dialog onClose={close} open={open} PaperProps={{
            sx: {
                width: "50%",
                maxHeight: 600
            }
        }}>
            <DialogTitle>Reject Loan Application (<LoanApplicationTitle  loanApplication={record} />)</DialogTitle>
            <Form record={{}} onSubmit={submit} validate={formValidate}>
                <DialogContent>
                    <DialogContentText>
                        Please provide details regarding rejection of the loan application {record.id}.
                    </DialogContentText>
                    <TextInput isRequired={true} multiline fullWidth label="Internal Rationale" source="internalRationale"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={props.isLoading} label="Reject"/>
                </DialogActions>
            </Form>
        </Dialog>

    );
}

export default RejectLoanApplicationDialog;
