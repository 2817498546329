import {Datagrid, EditButton, useRecordContext, useBasename} from "react-admin";
import * as React from "react";
import Resources from "../../Resources";
import {ShowButton} from "react-admin";

const ShowButtonWrapper = (props) => {
    const basename = useBasename();
    const record = useRecordContext(props);
    // console.log(record)
    return (
        <ShowButton to={"/" + Resources.PERSONS.name + "/" + record.id + "/show"} />

    );
}

export default ShowButtonWrapper;
