import {
    DateField, SimpleShowLayout, useShowContext, Datagrid, TextField, useList,
    ListContextProvider, FunctionField, RichTextField
} from "react-admin";
import * as React from "react";
import {COMMUNICATION_TYPE} from "../dicts/CallReportEnums";
import EnumDictField from "../controls/EnumDictField";
import PersonArrayField from "../controls/PersonArrayField";
import LegalEntityField from "../controls/LegalEntityField";
import PersonField from "../controls/PersonField";
import ProductApplicationField from "../controls/ProductApplicationField";

function CallReportShowForm(props) {
    const {record} = useShowContext();
    const contactPersonsListContext = useList({ data: record?.contactPersons });
    const productApplicationsContextList = useList({data: record?.productApplications})
    if(record) {
        if(record.monivoltReps) {
            record.monivoltRepsParticipantIds = record.monivoltReps.map((rep) => rep.participantId)
        }
    } else {
        return (<></>);
    }

    return (
        <SimpleShowLayout>
            <TextField label="Created By" source="createdBy"/>
            <DateField label="Communication Date" source="communicationDate"/>
            <EnumDictField enum={COMMUNICATION_TYPE} source="communicationType" />
            <PersonArrayField source="monivoltRepsParticipantIds" />
            <ListContextProvider value={contactPersonsListContext} label="Participants">
                <Datagrid bulkActionButtons={false}>
                    <LegalEntityField source="participantEmployerId" label="Organisation" />
                    <PersonField source="participantId" label="Contact" />
                </Datagrid>
            </ListContextProvider>
            <ListContextProvider value={productApplicationsContextList} label="Product applications">
                <Datagrid bulkActionButtons={false}>
                    <LegalEntityField source="partyId" label="Organisation" />
                    <FunctionField render={(record) => (
                        <ProductApplicationField applicationId={record.applicationId} partyId={record.partyId} applicationType={record.applicationType} />
                    )} label="Product" />
                </Datagrid>
            </ListContextProvider>
            <RichTextField source="content" label="Content of the communication" />
            <DateField source="nextStepsDate" label="Next Steps Date" />
            <RichTextField source="nextSteps" label="Next Steps" />
        </SimpleShowLayout>
    );
}

export default CallReportShowForm;