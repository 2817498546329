import PropTypes from 'prop-types'
import {AutocompleteInput, ReferenceInput} from "react-admin";
import Resources from "../../Resources";
import React from "react";
import {formatAmount} from "../../common/utils";

function LoanApplicationInput(props) {
    const getManyResource = Resources.LOAN_APPLICATIONS.name + "/" + Resources.LOAN_APPLICATIONS.actions.FIND_BY_IDS.name;
    const meta = {getManyResource, method: 'post'}

    const formatter = (value) => {
        if (value) {
            return value.id + ": " + formatAmount(value.loanCurrency, value.loanAmount) + " - " + value.borrowerCompanyFullName;
        }
        return 'N/A'
    }

    return (
        <ReferenceInput source={props.source}
                        enableGetChoices={({name}) => {return name && name.length > 2}}
                        reference={
                            Resources.LOAN_APPLICATIONS.name + "/"
                            + Resources.LOAN_APPLICATIONS.actions.FIND_BY_NAME.name
                        }
                        isRequired={props.isRequired}
                        queryOptions={{meta}}>
            <AutocompleteInput label={props.label} optionValue="id" onChange={props.onChange}
                               isRequired={props.isRequired}
                               filterToQuery={(searchText) => {return {name: searchText}}}
                               fullWidth={props.fullWidth} optionText={formatter}
                                />
        </ReferenceInput>
    );
}
export default LoanApplicationInput;

LoanApplicationInput.propTypes = {
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  source: PropTypes.string.isRequired,
}