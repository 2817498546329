import React from "react";
import {FunctionField, SimpleShowLayout, TextField} from "ra-ui-materialui";
import getCountries, {getCountryByCode} from "../../common/countries";
import PersonEmailField from "../controls/PersonEmailField";
import Typography from "@mui/material/Typography";
import {BooleanField, useTheme} from "react-admin";
import DateTimeFieldTZ from "../controls/DateFieldTZ";

function PersonDetails(props) {
    const {record} = props;
    const countries = getCountries();
    const [theme] = useTheme();

    if (!record) {
        return <></>
    }

    const getTextField = (person, initialLabel, personSource, applicationSource) => {
        let source;
        let label = initialLabel;
        if (person[personSource]) {
            source = personSource;
        }
        let appSource = applicationSource || personSource;
        if (person.loanApplication && person.loanApplication[appSource]) {
            source = "loanApplication." + appSource;
            label += " (from application)";
        }
        return source
            ? <TextField label={label} source={source}/>
            : getEmptyField(label);
    }

    const getEmailField = (person, initialLabel, personSource, applicationSource) => {
        let source;
        let label = initialLabel;
        if (person[personSource]) {
            source = personSource;
        }
        let appSource = applicationSource || personSource;
        if (person.loanApplication && person.loanApplication[appSource]) {
            source = "loanApplication." + appSource;
            label += " (from application)";
        }
        return source
            ? <PersonEmailField source={source} verifiedSource="emailVerified" label={label}/>
            : getEmptyField(label);
    }

    const getRegisteredAddress = (record) => {
        if (record.registeredAddress) {
            const country = getCountryByCode(record.registeredAddress.country, countries);
            return [
                <TextField label="Registered Address, address line 1" source="registeredAddress.addressLine1"/>,
                <TextField label="Registered Address, address line 2" source="registeredAddress.addressLine2"/>,
                <TextField label="City" source="registeredAddress.city"/>,
                <TextField label="Postal code" source="registeredAddress.postalCode"/>,
                <FunctionField label="Country" render={(record) => <>{country}</>}/>,
            ]
        }
        if (record.loanApplication) {
            const country = getCountryByCode(record.loanApplication.country, countries);
            return [
                <TextField label="Registered Address, address line 1" source="loanApplication.addressLine1"/>,
                <TextField label="Registered Address, address line 2" source="loanApplication.addressLine2"/>,
                <TextField label="City" source="loanApplication.city"/>,
                <TextField label="Postal code" source="loanApplication.postalCode"/>,
                <FunctionField label="Country" source="loanApplication.country" render={(record) => <>{country}</>}/>,
            ]
        }

        return getEmptyField("Registered Address");
    }

    const getResidency = (record) => {
        let residency;
        if (record.residency) {
            residency = getCountryByCode(record.residency, countries);
        }
        if (record.loanApplication && record.loanApplication.residency) {
            residency = getCountryByCode(record.loanApplication.residency, countries);
        }
        if (residency) {
            return <FunctionField label="Residency" source="loanApplication.residency" render={(record) => <>{residency}</>}/>
        }

        return getEmptyField("Residency");
    }

    const getCitizenship = (record) => {
        let citizenship = "";
        if (record.citizenship) {
            citizenship = record.citizenship.map((code) => getCountryByCode(code, countries)).join(" / ")
        }
        if (record.loanApplication && record.loanApplication.citizenship) {
            citizenship = record.loanApplication.citizenship.map((code) => getCountryByCode(code, countries)).join(" / ")
        }
        if (citizenship) {
            return <FunctionField label="Citizenship" source="loanApplication.citizenship" render={(record) => <>{citizenship}</>}/>
        }

        return getEmptyField("Citizenship");
    }

    const getEmptyField = (label) => {
        return <>
            <Typography className={`form-field-label form-field-label-${theme}`}>{label}</Typography>
            <Typography className={`form-field-value form-field-value-${theme}`}>-</Typography>
        </>;
    }

    return (
        <SimpleShowLayout record={record}>
            {getTextField(record, "First Name", "firstName", "name")}
            {getTextField(record, "Last Name", "lastName", "surname")}
            {getTextField(record, "Date of Birth", "birthDate")}
            <TextField source="title"/>
            {getEmailField(record, "Email", "email")}
            <TextField source="userLogin"/>
            <BooleanField label="Monivolt Representative" source="monivoltRepresentative"/>
            {getTextField(record, "Phone Number", "phoneNumber")}
            {getRegisteredAddress(record)}
            {getResidency(record)}
            {getCitizenship(record)}
            {getTextField(record, "Source of Wealth", "wealthSource")}
            <DateTimeFieldTZ source="createdDate" label={"Created Date (London)"}/>
        </SimpleShowLayout>
    );
}

export default PersonDetails;
