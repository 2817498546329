import {useRecordContext} from "react-admin";
import {FunctionField} from "ra-ui-materialui";
import {PartyRelations} from "../dicts/PartyPartyRelEnums";

function LegalEntityRelDetails({ source }) {
    const record = useRecordContext();
    let result = "";
    if(record) {
        let needSeparator = false;
        if(record.relationType) {
            result += PartyRelations[record.relationType].label;
            needSeparator = true;
        }
        if(record.shareAmount) {
            result += "/ ownership%: " + record.shareAmount;
            needSeparator = true;
        }
        if(record.relationTitle) {
            result += (needSeparator ? "/" : "") + record.relationTitle
        }
    }
    return (
        <FunctionField label="Relation" source={source} render={(record) => result} />
    );
}

export default LegalEntityRelDetails;
