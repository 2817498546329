export const PARTY_TYPE = {
    LEGAL_ENTITY: 'LEGAL_ENTITY',
    PERSON: 'PERSON'
};

export const MAX_CURRENCY_SCALE = 20;

export const DICTS = {
    STORE_CURRENCY_SCALES_MAP: null,
    STORE_CURRENCY_LABELS_MAP: null,
}