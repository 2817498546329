import {DateInput, TextInput} from "react-admin";
import React from "react";
import {PartyInput} from "../controls/PartyInput";
import {PARTY_TYPE} from "../../common/constants";
import Grid from "@mui/material/Grid";
import {UserInput} from "../controls/UserInput";
import Resources from "../../Resources";
import DictInput from "../controls/DictInput";

export const LoanApplicationAuditReport = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <DateInput source="params.dateFrom" label="Event Date From" required fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <DateInput source="params.dateTo" label="Event Date To" fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <TextInput source="params.loanApplicationId" required={false} label="Loan Application ID" fullWidth/>
            </Grid>
            <Grid item xs={3}>
                <DictInput label="Event Type" source="params.eventType"
                           reference={Resources.DICT_AUDIT_EVENT_TYPES.name}/>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={5}>
                <PartyInput source="params.creditorId" required={false} fixedPartyType={PARTY_TYPE.LEGAL_ENTITY}
                            label="Creditor" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <UserInput source="params.author" required={false} label="Author" fullWidth/>
            </Grid>
        </Grid>
    )
}