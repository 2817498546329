import {DateInput} from "react-admin";
import React from "react";
import Grid from "@mui/material/Grid";
import {UserInput} from "../controls/UserInput";
import {DataroomAuditEventType} from "../dicts/LoanApplicationEnum";
import EnumDictInput from "../controls/EnumDictInput";
import LoanApplicationInput from "../controls/LoanApplicationInput";

export const DataroomAuditReport = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <DateInput source="params.dateFrom" label="Event Date From" required fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <DateInput source="params.dateTo" label="Event Date To" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <LoanApplicationInput source="params.loanApplicationId" required={false}
                                      label="Loan Application ID (search by Borrower)"
                                      fullWidth />
            </Grid>
            <Grid item xs={3}>
                <EnumDictInput enum={DataroomAuditEventType} label="Event Type" source="params.auditEventType" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <UserInput source="params.author" required={false} label="Event Initiator Login" fullWidth/>
            </Grid>
        </Grid>
    )
}