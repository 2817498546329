import PropTypes from 'prop-types'
import {AutocompleteArrayInput, ReferenceArrayInput} from "react-admin";
import Resources from "../../Resources";
import React from "react";

function PersonArrayInput(props) {
    return (
        <ReferenceArrayInput source={props.source} enableGetChoices={({name}) => {return name && name.length > 2}} filter={props.additionalFilter} reference={Resources.PERSONS.name} isRequired={props.isRequired}>
            <AutocompleteArrayInput label={props.label} optionValue="id" optionText={(person) => (person.id + ": " + person.firstName + " " + person.lastName)} filterToQuery={(searchText) => {return {name: searchText}}} />
        </ReferenceArrayInput>
    );
}

export default PersonArrayInput;

PersonArrayInput.propTypes = {
  additionalFilter: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  source: PropTypes.string.isRequired
}