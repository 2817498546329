import {Create, SimpleForm, TextInput} from "react-admin";
import Resources from "../../Resources";
import LegalEntityInput from "../controls/LegalEntityInput";
import PersonInput from "../controls/PersonInput";
import EnumDictInput from "../controls/EnumDictInput";
import {PartyRelations} from "../dicts/PartyPartyRelEnums";
import React from "react";

function PartPartyRelCreatePage(props) {
    function redirect(resource, id, data) {
        history.back();
        return false;
    }
    const validateRelationForm = (values) => {
        const errors = {};
        if (!values.toId) {
            errors.toId = 'Organisation is required';
        }
        if (!values.fromId) {
            errors.fromId = 'Person is required';
        }
        if(!values.relationType) {
            errors.relationType = 'Relation Type is required';
        }
        return errors
    }
    return (
        <Create resource={Resources.PARTY_PARTY_REL.name} redirect={redirect}>
            <SimpleForm validate={validateRelationForm}>
                <LegalEntityInput source="toId" label="Organisation" isRequired fullWidth />
                <PersonInput source="fromId" label="Contact Person" isRequired fullWidth />
                <EnumDictInput enum={PartyRelations} source="relationType" fullWidth label="Relation Type" required />
                <TextInput source="relationTitle" fullWidth />
            </SimpleForm>
        </Create>
    );
}

export default PartPartyRelCreatePage;