/**
 * Confirmation dialog: Yes, No, Cancel
 * @example
 * <ConfirmEx
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirmColor="primary"
 *     labelYes="Yes"
 *     labelNo="No"
 *     labelCancel="Cancel"
 *     YesIcon=IconYes
 *     NoIcon=IconNo
 *     CancelIcon=IconCancel
 *     onClickYes={() => { // do something }}
 *     onClickNo={() => { // do something }}
 *     onCancel={() => { // do something }}
 * />
 */

import * as React from 'react'
import {useCallback} from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import {alpha, styled} from '@mui/material/styles'
import IconYes from '@mui/icons-material/CheckCircle'
import IconNo from '@mui/icons-material/Cancel'
import IconCancel from '@mui/icons-material/ErrorOutline'
import {useTranslate} from 'react-admin'

const ConfirmEx = props => {
    const {
        isOpen,
        loading,
        title,
        content,
        labelYes,
        labelNo,
        labelCancel,
        confirmColor,
        YesIcon,
        NoIcon,
        CancelIcon,
        onClickYes,
        onClickNo,
        onCancel,
        translateOptions = {},
    } = props

    const translate = useTranslate()

    const handleYes = useCallback(
        e => {
            e.stopPropagation()
            onClickYes(e)
        }, [onClickYes])

    const handleNo = useCallback(
        e => {
            e.stopPropagation()
            onClickNo(e)
        }, [onClickNo])

    const handleClick = useCallback(e => { e.stopPropagation() }, [])

    const YesButton = styled((props) => (
        <Button
            disabled={loading}
            onClick={handleYes}
            autoFocus
        >
            <YesIcon sx={{paddingRight: '0.5em'}} />
            { translate(labelYes, { _: labelYes }) }
        </Button>
    ))(({ theme }) => ({
        confirmPrimary: {
            color: theme.palette.primary.main,
        },
        confirmWarning: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                '@media (hover: none)': { // Reset on mouse devices
                    backgroundColor: 'transparent',
                },
            },
        },
        iconPaddingStyle: {
            paddingRight: '0.5em',
        },
    }));

    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
            onClick={handleClick}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {translate(title, { _: title, ...translateOptions })}
            </DialogTitle>
            <DialogContent>
                { typeof content === 'string' ? (
                    <DialogContentText>
                        { translate(content, { _: content, ...translateOptions }) }
                    </DialogContentText>
                ) : (content)
                }
            </DialogContent>
            <DialogActions>
                <YesButton/>
                <Button disabled={loading} onClick={handleNo}>
                    <NoIcon sx={{paddingRight: '0.5em'}} />
                    { translate(labelNo, { _: labelNo }) }
                </Button>
                <Button disabled={loading} onClick={onCancel}>
                    <CancelIcon sx={{paddingRight: '0.5em'}} />
                    { translate(labelCancel, { _: labelCancel }) }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmEx.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    classes: PropTypes.object,
    confirmColor: PropTypes.string,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    labelYes: PropTypes.string,
    labelNo: PropTypes.string,
    labelCancel: PropTypes.string,
    YesIcon: PropTypes.elementType,
    NoIcon: PropTypes.elementType,
    CancelIcon: PropTypes.elementType,
    onClickYes: PropTypes.func.isRequired,
    onClickNo: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

ConfirmEx.defaultProps = {
    classes: {},
    isOpen: false,
    confirmColor: 'primary',
    labelYes: 'ra.boolean.true',
    labelNo: 'ra.boolean.false',
    labelCancel: 'ra.action.cancel',
    YesIcon: IconYes,
    NoIcon: IconNo,
    CancelIcon: IconCancel,
}

export default ConfirmEx
