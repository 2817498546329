import {Edit} from "react-admin";
import PersonsForm from "./PersonsForm";
import * as React from "react";

function PersonsEditPage(props) {
    return (
        <Edit>
            <PersonsForm />
        </Edit>
    );
}

export default PersonsEditPage;