import PropTypes from 'prop-types'
import React from "react";
import {Button, Grid, Typography} from '@mui/material';
import {
    DateInput,
    FilterButton,
    List,
    TextInput,
    TopToolbar,
    useDataProvider,
    useNotify,
    useRedirect
} from "react-admin";
import CallReportDatagrid from "./CallReportDatagrid";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import PaginationBar from "../controls/PaginationBar";


const CallReportFilters = [
    <TextInput source="createdByIgnoreCase" label="Created By" resettable alwaysOn />,
    <TextInput source="fullCompanyName" label="Organisation" resettable alwaysOn />,
    <DateInput source="communicationAfter" alwaysOn />,
    <DateInput source="communicationBefore" alwaysOn />,
    <DateInput source="nextStepsAfter" />,
    <DateInput source="nextStepsBefore" />,
];

function CallReportListPage(props) {

    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const defaultCallReportData = props.defaultCallReportData || {};

    const createDraftAndEditCallReportAction = () => {

        dataProvider.create(Resources.CALL_REPORT.name, {
            data: {...defaultCallReportData},
        }).then(({data}) => {
            if (data.id && data.id > 0) {
                redirect("edit", Resources.CALL_REPORT.name, data.id);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
        });

    }

    const CreateDraftButton = (props) => {
        return (<Button size="small" sx={{lineHeight: 1.5}} onClick={createDraftAndEditCallReportAction}>Create</Button>)
    }

    const EmptyLayout = (props) => {
        return (
            <Grid container flexDirection="column" padding={20}>
                <Grid item textAlign="center">
                        <Typography variant="h6" >
                            No meeting notes available
                        </Typography>
                        <CreateDraftButton />
                </Grid>
            </Grid>
        );
    }

    const ListActions = () => (
        <TopToolbar>
            {!props.hideFilters && <FilterButton filters={CallReportFilters} />}
            <CreateDraftButton />
        </TopToolbar>
    );

    return (
        <List filters={props.hideFilters ? null : CallReportFilters} resource={props.resource} filter={props.filter}
              actions={<ListActions />}
              empty={<EmptyLayout />}
              pagination={<PaginationBar/>} perPage={20}
              sort={{field: "id", order: "DESC"}}>
            <CallReportDatagrid/>
        </List>
    );
};

export default CallReportListPage;

CallReportListPage.propTypes = {
  defaultCallReportData: PropTypes.object,
  filter: PropTypes.object,
  hideFilters: PropTypes.bool,
  resource: PropTypes.string
}