import PropTypes from 'prop-types'
import React from "react";
import {SelectInput} from "react-admin";
import {CurrencyType} from "../dicts/ExchangeEnums";

export function CurrencyTypeSelectInput(props) {
    const {filter, source, disabled, validate, ...rest} = props;
    const arr = Object.values(CurrencyType).map(v=>({id: v.code, name: v.label}));
    let choices = arr;
    if (filter) {
        const flt = filter.reduce((a, v) => ({ ...a, [v]: v}), {})
        choices = [];
        arr.forEach(e => {
            if (flt[e.id]) {
                choices.push(e);
            }
        })
    }
    // noinspection RequiredAttributes
    return (
        <SelectInput
            source={source}
            disabled={props.disabled}
            choices={choices}
            validate={validate}
            {...rest}
        />
    );
}

CurrencyTypeSelectInput.propTypes = {
  disabled: PropTypes.bool,
  filter: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string.isRequired,
  validate: PropTypes.func
}
