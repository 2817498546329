import {CheckPermission} from "../security/CheckPermission";
import {AdminPortalRoles} from "../dicts/Security";
import {BulkDeleteWithConfirmButton} from "react-admin";
import * as React from "react";

const AuthenticatedBulkDeleteAction = () => (
    <>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_APPROVER}>
            <BulkDeleteWithConfirmButton confirmContent="You will not be able to recover this records. Are you sure?" />
        </CheckPermission>
    </>
);

export default AuthenticatedBulkDeleteAction;

AuthenticatedBulkDeleteAction.propTypes = {
}
