import {Create} from "react-admin";
import LegalEntitiesForm from "./LegalEntitiesForm";

function LegalEntitiesCreatePage(props) {
    return (
        <Create redirect="list">
            <LegalEntitiesForm />
        </Create>
    )
}

export default LegalEntitiesCreatePage;