import PropTypes from 'prop-types'
import {Button, useRedirect} from 'react-admin';
import React from "react";
import Resources from "../../Resources";

function PartyPartyRelCreateButton(props) {
    const redirect = useRedirect();

    const {toId, fromId} = props;

    function createPartyRel() {
        redirect("create", Resources.PARTY_PARTY_REL.name, null, null, {record:{toId, fromId}})
    }

    return (
        <>
            <Button label="Add Party relation" onClick={createPartyRel} />
        </>
    );
};

export default PartyPartyRelCreateButton;

PartyPartyRelCreateButton.propTypes = {
  toId: PropTypes.number,
  fromId: PropTypes.number,
}