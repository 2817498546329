import {List, Show, Tab, TabbedShowLayout, TopToolbar, useShowController} from 'react-admin';
import Resources from "../../Resources";
import LegalEntityDetails from "../entityDetails/LegalEntityDetails";
import React from "react";
import PartyPartyRelCreateButton from "../partyPartyRels/PartyPartyRelCreateButton";
import PartyPartyRelDatagrid from "../partyPartyRels/PartyPartyRelDatagrid";
import CallReportListPage from "../callReports/CallReportListPage";


function LegalEntitiesShowPage() {

    const {record} = useShowController();

    const {id} = record || {};
    // const personResource = useMemo(()=>Resources.LEGAL_ENTITY.name + "/" + id + "/" + Resources.PERSONS.name, [id])
    // let personsHook = useGetList(personResource, {}, {enabled: !!id});
    // let personsListContext = useList(personsHook);

    if (!record) return <>No data found</>;

    function LinkedPersonsActions(props) {
        return (
            <>
                <TopToolbar>
                    <PartyPartyRelCreateButton toId={id} />
                </TopToolbar>
            </>
        );
    }

    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Legal Entity">
                    <LegalEntityDetails record={record} />
                </Tab>
                <Tab label="Persons">
                    {id &&
                        <List actions={<LinkedPersonsActions />} empty={<LinkedPersonsActions />}
                              resource={Resources.PARTY_PARTY_REL.name} filter={{toId: id}}>
                            <PartyPartyRelDatagrid showFromEntity />
                        </List>
                    }
                </Tab>
                <Tab label="Meeting Notes">
                    {id &&
                        <CallReportListPage resource={Resources.CALL_REPORT.name}
                                            filter={{participantEmployerId: id}}
                                            hideFilters
                        />
                    }
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export default LegalEntitiesShowPage;

