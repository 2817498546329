import {Datagrid, FunctionField, ListContextProvider, TextField, UrlField, useGetList, useList} from "react-admin";
import DictField from "../controls/DictField";
import Resources from "../../Resources";
import * as React from "react";
import DateTimeFieldTZ from "../controls/DateFieldTZ";
import Typography from "@mui/material/Typography";
import {DatagridEmpty} from "../controls/DatagridEmpty";
import Grid from "@mui/material/Grid";

function DocumentsDataList(props) {
    const {partyId, partyIds, partyDict} = props;
    let partyFilter = [];
    if (partyIds) {
        partyFilter = partyFilter.concat(partyIds)
    }
    partyFilter.push(partyId);

    let documentsHook = useGetList("parties/" + partyId + "/documents",
        {
            filter: {partyIds: partyFilter}
        });
    let documentListContext = useList(documentsHook);

    return (
        <ListContextProvider value={documentListContext}>
            <Grid container spacing={0} className="grid-header">
                <Grid item xs={12}>
                    <Typography className="grid-header">Customer Documents</Typography>
                </Grid>
            </Grid>
            <Datagrid data-testid="docs-list-grid" empty={<DatagridEmpty/>} bulkActionButtons={false}>
                <TextField source="id"/>
                <DateTimeFieldTZ source="createdDate" label={"Attached Date (London)"}/>
                <TextField source="partyId" label="Party Id"/>
                <FunctionField label="Party" render={record => partyDict[record.partyId]?.name}/>
                <DictField label="Document Type" source="docTypeCode" reference={Resources.DICT_DOC_TYPES.name}/>
                <FunctionField label="File Name" render={record => <UrlField
                    href={`/api/v1/parties/${record.ownerId}/documents/${record.id}/content` }
                    label="File Name" source="filename"/>}/>
            </Datagrid>
        </ListContextProvider>
    );
}

export default DocumentsDataList;
