import {DateInput} from "react-admin";
import React from "react";
import Grid from "@mui/material/Grid";
import LoanApplicationInput from "../controls/LoanApplicationInput";

export const DataroomAuditSummaryReport = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <DateInput source="params.dateFrom" label="Event Date From" fullWidth/>
            </Grid>
            <Grid item xs={2}>
                <DateInput source="params.dateTo" label="Event Date To" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <LoanApplicationInput source="params.loanApplicationId" required
                                      label="Loan Application ID (search by Borrower)"
                                      fullWidth />
            </Grid>
        </Grid>
    )
}