import {Edit, SimpleForm, TextInput} from "react-admin";
import EnumDictInput from "../controls/EnumDictInput";
import {PartyRelations} from "../dicts/PartyPartyRelEnums";
import React from "react";
import LegalEntityField from "../controls/LegalEntityField";
import PersonField from "../controls/PersonField";

export default function PartPartyRelEditPage (props) {
    const validateRelationForm = (values) => {
        const errors = {};
        if (!values.toId) {
            errors.toId = 'Organisation is required';
        }
        if (!values.fromId) {
            errors.fromId = 'Person is required';
        }
        if(!values.relationType) {
            errors.relationType = 'Relation Type is required';
        }
        return errors
    }

    const redirectFn = (resource, id, data) => {
        history.back();
        return false;
    }

    return (
        <Edit redirect={redirectFn}>
            <SimpleForm validate={validateRelationForm}>
                <LegalEntityField source="toId" label="Organisation" />
                <PersonField source="fromId" label="Contact Person" />
                <EnumDictInput enum={PartyRelations} source="relationType" fullWidth label="Relation Type" required />
                <TextInput source="relationTitle" fullWidth />
            </SimpleForm>
        </Edit>
    );
}

PartPartyRelEditPage.propTypes = {
}