import {List, TextInput, TopToolbar, Button, CreateButton, ExportButton} from "react-admin";
import LegalEntitiesDatagrid from "../controls/LegalEntitiesDatagrid";
import {useState} from "react";
import MergeDialog from "./MergeDialog";

const legalEntitiesFilters = [
    // eslint-disable-next-line react/jsx-key
    <TextInput source="name" alwaysOn />,
    // eslint-disable-next-line react/jsx-key
    <TextInput source="registrationNumber" alwaysOn />,
    // eslint-disable-next-line react/jsx-key
    <TextInput source="description" alwaysOn />,
];

const MergeButton = (props) => {
    const [isMergeDialogOpened, setIsMergeDialogOpened] = useState(false);
    const openMergeDialog = () => {
        setIsMergeDialogOpened(true);
    };
    const closeMergeDialog = () => {
        setIsMergeDialogOpened(false);
    };

    return (
        <>
            {isMergeDialogOpened && <MergeDialog open={isMergeDialogOpened} close={closeMergeDialog}/>}
            <Button onClick={() => openMergeDialog()} label="Merge Legal Entities"/>
        </>
    )
}

const ListActions = () => {
    return (
        <TopToolbar>
            <CreateButton/>
            <ExportButton/>
            <MergeButton/>
        </TopToolbar>
    )
}

function LegalEntitiesListPage(props) {
    return (
        <List filters={legalEntitiesFilters} actions={<ListActions/>} sort={{field: "id", order: "DESC"}}>
            <LegalEntitiesDatagrid />
        </List>
    );
}

export default LegalEntitiesListPage;
