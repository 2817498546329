export const LoanApplicationCreditorStatus = {
    NEW: {
        code: "NEW", label: "New",
        desc: "Only brief description is available, non-names basis"
    },
    DUE_DILIGENCE: {
        code: "DUE_DILIGENCE", label: "Due Diligence",
        desc: "When NDA attached by a creditor and NDA accepted by a backoffice. Full data is available for the creditor"
    },
    FUNDED: {
        code: "FUNDED", label: "Funded",
        desc: "When a creditor funded the opportunity"
    },
    GONE: {
        code: "GONE", label: "Gone",
        desc: "When there is no possibility to fund it. The loan application has been funded by another creditor or rejected by backoffice"
    },
    DECLINED: {
        code: "DECLINED", label: "Declined",
        desc: "When creditor declined the opportunity"
    },
}