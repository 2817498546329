import React, {useRef, useState} from "react";
import {useDataProvider, useInput} from "react-admin";
import Resources from "../../Resources";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from "prop-types";

export function UserInput(props) {
    const [userInputTextValue, setUserInputTextValue] = useState("");
    const [options, setOptions] = useState([]);

    // it is necessary to reset value of inputTest of autocomplete on changing party type
    // see https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
    const [autocompleteKey, setAutocompleteKey] = useState(Date.now());
    const previousController = useRef();
    const dataProvider = useDataProvider();
    const { onChange, onBlur, ...rest } = props;
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired
    } = useInput({
        onChange,
        onBlur,
        ...props,
    });

    const loadPersons = (searchName) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        // const signal = controller.signal;
        previousController.current = controller;
        const filter = {
            name: searchName,
        };
        const mapper = (entity) => {
            return {id: entity.id, label: `${entity.firstName} ${entity.lastName} (${entity.userLogin})`}
        };

        dataProvider.getList(Resources.USERS.name, {
            filter: filter,
            // sort: { field: 'userLogin', order: 'DESC' }
        }).then(({ data }) => {
            // console.log("searchData", data);
            const items = data.map(mapper);
            setOptions(items);
        }).catch(error => {
            console.log("got error", error)
        })
    };

    const onInputChange = (event, value, reason) => {
        if (value) {
            loadPersons(value);
            setUserInputTextValue(value)
        } else {
            setOptions([]);
        }
    };
    return (
        <Autocomplete
            name={props.source}
            key={autocompleteKey}
            options={options} fullWidth
            onInputChange={onInputChange}
            onChange={(event, value) => {
                field.onChange(value ? value.id : null);
                if (props.onChange)
                    props.onChange(value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.id + ": " + option.label}
            renderInput={(params) => (
                <TextField {...params} label={props.label || "User"} variant="filled"
                           error={(isTouched || isSubmitted) && invalid}
                           value={userInputTextValue}
                           helperText={(isTouched || isSubmitted) && invalid ? error : ''}
                           required={isRequired}
                           {...rest} />
            )}

        />
    );
}


UserInput.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    required: PropTypes.bool,
    source: PropTypes.string.isRequired,
    onChange: PropTypes.func,
}
