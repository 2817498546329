import PropTypes from 'prop-types'
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
    ArrayInput,
    Button,
    FileField,
    FileInput,
    Form,
    SimpleFormIterator,
    useSimpleFormIteratorItem
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import SelectNewCreditorsGrid from "./SelectNewCreditorsGrid";
import LoanApplicationCommonDataEditForm from "./LoanApplicationCommonDataEditForm";
import LegalEntityInput from "../controls/LegalEntityInput";
import Resources from "../../Resources";
import CloseIcon from '@mui/icons-material/RemoveCircleOutline';
import {useWatch} from "react-hook-form";
import LoanApplicationTitle from "../controls/LoanApplicationTitle";

const COMMON_DATA_STEP = 0;
const CREDITORS_STEP = 1;
const NON_VERIFIED_CREDITORS_STEP = 2;

export function CapitalSourcingDialog(props) {

    const {close, record, isLoading, open, submit} = props;
    const [creditorIds, setCreditorIds] = useState([])
    const [nonVerifiedCreditorIds, setNonVerifiedCreditorIds] = useState([])
    const [formStep, setFormStep] = useState(COMMON_DATA_STEP);
    const [commonDataFormState, setCommonDataFormState] = useState({...record});

    const removeNonVerifiedCreditor = (id) => {
        if(nonVerifiedCreditorIds.includes(id)) {
            setNonVerifiedCreditorIds([...nonVerifiedCreditorIds.filter((val) => val !== id)])
        }
    }

    const addNonVerifiedCreditor = (id) => {
        if(!nonVerifiedCreditorIds.includes(id)) {
            setNonVerifiedCreditorIds([...nonVerifiedCreditorIds, id])
        }
    }

    const nonVerifiedFormDefaultValue = () => ({nonVerifiedCreditorIds: nonVerifiedCreditorIds.map(partyId => ({partyId}))})

    const WatchedArrayInput = (props) => {
        const nonVerifiedCreditorIds = useWatch({name: "nonVerifiedCreditorIds"})

        const RemoveButton = () => {
            const {remove, index, total} = useSimpleFormIteratorItem();
            const removeFromNonVerifiedCreditors = (e) => {
                if (nonVerifiedCreditorIds && nonVerifiedCreditorIds.length && index && index < total) {
                    const v = nonVerifiedCreditorIds[index]
                    if (v && v.partyId) {
                        removeNonVerifiedCreditor(v.partyId)
                    }
                }
                remove();
            }
            return <CloseIcon fontSize="small" color="primary" onClick={removeFromNonVerifiedCreditors}/>;
        }

        return (
            <ArrayInput source="nonVerifiedCreditorIds" fullWidth label="">
                <SimpleFormIterator fullWidth inline disableReordering
                                    removeButton={<RemoveButton/>}>
                    <LegalEntityInput
                        source="partyId" label="Organisation" fullWidth
                        resource={Resources.CREDITORS.name + "/"
                            + Resources.CREDITORS.actions.FIND_NON_VERIFIED_BY_NAME.name}
                        onChange={
                            (v, r) => {
                                addNonVerifiedCreditor(v)
                                // console.log("LegalEntityInput", v, r)
                            }
                        }
                    />
                </SimpleFormIterator>
            </ArrayInput>
        )
    }

    const handleClose = (event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        close();
    }

    const validateCommonDataForm = (form) => {
        const errors = {};
        if (!form.documentFile) {
            errors.documentFile = "Brief description document is required";
        }
        if (form.irrFrom === 0) {
            errors.irrFrom = "Must be more than 0"
        }
        if (form.irrTo && form.irrFrom > form.irrTo) {
            errors.irrTo = "Must be more than Indicative Return From value"
        }
        return errors;
    }

    const validateCreditorsForm = (form) => {
        const errors = {};
        if (creditorIds.length === 0 && nonVerifiedCreditorIds.length === 0 && formStep === NON_VERIFIED_CREDITORS_STEP) {
            errors.creditorArray = "At least one creditor has to be added";
        }
        return errors;
    }

    const onCommonDataFormSubmit = (data) => {
        setCommonDataFormState({...data});
        setFormStep(CREDITORS_STEP);
    }

    const back = (data) => {
        // setCommonDataFormState()
        setFormStep(formStep === COMMON_DATA_STEP ? COMMON_DATA_STEP : formStep - 1);
    }

    function onFormSubmit(data) {
        if (formStep === CREDITORS_STEP && !commonDataFormState.isBorrowerConfirmationRequired
            || formStep === NON_VERIFIED_CREDITORS_STEP) {
            // const nonVerified = data.nonVerifiedCreditorIds.map(e => e.partyId)
            submit({
                ...commonDataFormState,
                creditorIds: creditorIds.toString(),
                nonVerifiedCreditorIds: nonVerifiedCreditorIds.toString()
            })
        } else {
            setFormStep(formStep + 1)
        }
    }

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
            <DialogTitle>Capital Sourcing Dialog (<LoanApplicationTitle  loanApplication={props.record} />)</DialogTitle>
            {formStep === COMMON_DATA_STEP && <>
                <Form record={commonDataFormState} onSubmit={onCommonDataFormSubmit} validate={validateCommonDataForm}>
                    <DialogContent>
                        <DialogContentText>
                            Please attach Brief description document and fill in loan application parameters.
                        </DialogContentText>
                        <Grid container spacing={1}>
                            <Grid item xs={11}>
                                <FileInput source="documentFile" multiple={false} fullWidth accept="application/pdf">
                                    <FileField source="src" title="title" fullWidth/>
                                </FileInput>
                            </Grid>
                            <Grid container>
                                <LoanApplicationCommonDataEditForm/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={close} label="Cancel"/>
                        <Button type="submit" label="Next"/>
                    </DialogActions>
                </Form>
            </>}
            {formStep === CREDITORS_STEP && <>
                <Form record={record} onSubmit={onFormSubmit} validate={validateCreditorsForm}>
                    <DialogContent>
                        <DialogContentText>
                            Choose appropriate credit officers and press Submit.
                        </DialogContentText>
                        <Grid container spacing={1}>
                            <Grid item xs={11}>
                                <SelectNewCreditorsGrid borrowerType={commonDataFormState.borrowerTypeCode}
                                                        country={record.rawdata.country}
                                                        region={record.regionCode}
                                                        loanAmount={record.loanAmount}
                                                        loanCurrency={record.currency}
                                                        sector={commonDataFormState.sectorCode}
                                                        businessStage={commonDataFormState.businessStageCode}
                                                        loanStructure={commonDataFormState.loanStructureCode}
                                                        revenue={commonDataFormState.revenue}
                                                        ebitda={commonDataFormState.ebitda}
                                                        enterpriseValue={commonDataFormState.enterpriseValue}
                                                        useOfFunds={commonDataFormState.useOfFundsCode}
                                                        loanTerm={record.loanTerm}
                                                        loanIrrTo={commonDataFormState.irrTo}
                                                        loanType={record.loanTypeCode}
                                                        loanApplicationId={record.id}
                                                        creditorIds={creditorIds} setCreditorIds={setCreditorIds}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={back} label="Back"/>
                        <Button type="submit" disabled={isLoading}
                                label={!commonDataFormState.isBorrowerConfirmationRequired ? "Submit" : "Next"}/>
                    </DialogActions>
                </Form>
            </>}
            {formStep === NON_VERIFIED_CREDITORS_STEP && !!commonDataFormState.isBorrowerConfirmationRequired && <>
                <Form record={record} onSubmit={onFormSubmit} defaultValues={nonVerifiedFormDefaultValue}>
                    <DialogContent>
                        <DialogContentText>
                            Add non-verified Capital Partners if necessary and press Submit.
                        </DialogContentText>
                        <WatchedArrayInput/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={back} label="Back"/>
                        <Button type="submit"
                                disabled={isLoading
                                    || (creditorIds.length === 0 && nonVerifiedCreditorIds.length === 0)}
                                label="Submit"/>
                    </DialogActions>
                </Form>
            </>}
        </Dialog>
    );
}

CapitalSourcingDialog.propTypes = {
    close: PropTypes.func,
    isLoading: PropTypes.bool,
    open: PropTypes.bool,
    record: PropTypes.object,
    submit: PropTypes.func
}
