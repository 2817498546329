import PropTypes from 'prop-types'
import {ReferenceField} from "react-admin";
import Resources from "../../Resources";

function PersonField(props) {
    return (
        <ReferenceField link="show" source={props.source} label={props.label} reference={Resources.PERSONS.name} />
    );
}

export default PersonField;

PersonField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired
}