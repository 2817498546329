export const SettingTypeEnum = {
    STRING: {
        code: 'STRING'
    },
    TEXT: {
        code: 'TEXT'
    },
    INT: {
        code: 'INT'
    },
    FLOAT: {
        code: 'FLOAT'
    },
    DOCUMENT: {
        code: 'DOCUMENT'
    },
}