import {Edit} from "react-admin";
import LegalEntitiesForm from "./LegalEntitiesForm";

function LegalEntitiesEditPage(props) {
    return (
        <Edit>
            <LegalEntitiesForm />
        </Edit>
    );
}

export default LegalEntitiesEditPage;