import PropTypes from 'prop-types'
import Resources from "../../Resources";
import {ReferenceArrayField, SingleFieldList, ChipField, FunctionField } from "react-admin";
import Chip from '@mui/material/Chip';

function PersonArrayField(props) {
    return (
            <ReferenceArrayField source={props.source} reference={Resources.PERSONS.name} label={props.label}>
                <SingleFieldList linkType="show">
                    <FunctionField render={(rec) => (<Chip label={rec.id + ": " + rec.firstName + " " + rec.lastName} />)} />
                </SingleFieldList>
            </ReferenceArrayField>
        );
}

PersonArrayField.propTypes = {
  label: PropTypes.any,
  source: PropTypes.any.isRequired
}

export default PersonArrayField;