import React, {useRef, useState} from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {PARTY_TYPE} from "../../common/constants";
import {useDataProvider, useInput} from "react-admin";
import Resources from "../../Resources";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from "prop-types";
import {AccountInput} from "./AccountInput";

export function PartyInput(props) {
    const [partyType, setPartyType] = useState(PARTY_TYPE.PERSON);
    const [partyInputTextValue, setPartyInputTextValue] = useState("");
    const [options, setOptions] = useState([]);

    // it is necessary to reset value of inputTest of autocomplete on changing party type
    // see https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
    const [autocompleteKey, setAutocompleteKey] = useState(Date.now());
    const previousController = useRef();
    const dataProvider = useDataProvider();
    const { onChange, onBlur, additionalFilter, fixedPartyType, alwaysOn, ...rest } = props;
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired
    } = useInput({
        onChange,
        onBlur,
        ...props,
    });

    const loadParties = (searchName) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        let resourceName;
        let filter;
        let mapper;
        let partyTypeLocal = fixedPartyType ? fixedPartyType : partyType;
        if(partyTypeLocal === PARTY_TYPE.PERSON) {
            resourceName = Resources.PERSONS.name;
            filter = {
                name: searchName,
                ...additionalFilter
            };
            mapper = (person) => {
                return {id: person.id, label: person.firstName + " " + person.lastName}
            };
        } else {
            resourceName = Resources.LEGAL_ENTITY.name
            filter = {
                name: searchName,
                ...additionalFilter
            };
            mapper = (entity) => {
                return {id: entity.id, label: entity.fullCompanyName}
            };
        }

        dataProvider.getList(resourceName, {
            pagination: { page: 0, perPage: 30 },
            filter: filter,
            // sort: { field: 'published_at', order: 'DESC' }
        }).then(({ data }) => {
            // console.log("searchData", data);
            const items = data.map(mapper);
            setOptions(items);
        }).catch(error => {
            console.log("got error", error)
        })

    };

    const onInputChange = (event, value, reason) => {
        if (value) {
            loadParties(value);
            setPartyInputTextValue(value)
        } else {
            setOptions([]);
        }
    };
    return (
        <div className="PartyInput-container">
            {!fixedPartyType &&
                <RadioGroup
                    row
                    name="Party type"
                    value={partyType}
                    onChange={(event) => {
                        setPartyType(event.target.value);
                        setOptions([]);
                        field.onChange({id: null, label: ''});
                        setAutocompleteKey(Date.now())
                    }}
                    className="PartyInput-labels"
                >
                    <FormControlLabel value={PARTY_TYPE.PERSON} control={<Radio/>} label="Person"/>
                    <FormControlLabel value={PARTY_TYPE.LEGAL_ENTITY} control={<Radio/>} label="Legal entity"/>
                </RadioGroup>
            }
            <Autocomplete
                id="combo-box-demo"
                multiple={props.multiple}
                className="PartyInput-autocomplete"
                name={props.source}
                key={autocompleteKey}
                options={options} fullWidth
                onInputChange={onInputChange}
                onChange={(event, value) => {
                    if(!props.multiple) {
                        field.onChange(value ? value.id : null);
                    } else {
                        field.onChange(value.map((party) => party.id));
                    }
                    if (props.onChange)
                        props.onChange(value);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.id + ": " + option.label}
                renderInput={(params) => (
                    <TextField {...params} label={props.label || "Owner"} variant="filled"
                               error={(isTouched || isSubmitted) && invalid }
                               value={partyInputTextValue}
                               helperText={(isTouched || isSubmitted) && invalid && error ? error.message?.message : ''}
                               required={isRequired}
                               {...rest} />
                )}

            />
        </div>
    );
}


PartyInput.propTypes = {
  additionalFilter: PropTypes.object,
  disabled: PropTypes.bool,
  fixedPartyType: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  alwaysOn: PropTypes.bool,
  onBlur: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  source: PropTypes.string.isRequired
}
