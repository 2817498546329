import PropTypes from 'prop-types'
import React from "react";
import Resources from "../../Resources";
import {Datagrid, List, TextField} from "react-admin";
import DateTimeFieldTZ from "../controls/DateFieldTZ";
import {DataroomAuditEventType} from "../dicts/LoanApplicationEnum";
import EnumDictField from "../controls/EnumDictField";
import PaginationBar from "../controls/PaginationBar";

function DataroomAuditList(props) {
    const {loanApplication} = props;

    return (<List disableSyncWithLocation={true}
                  resource={Resources.LOAN_APPLICATIONS.name + "/" + loanApplication.id
                      + "/" + Resources.LOAN_APPLICATIONS.subrequests.DATA_ROOM.name
                      + "/" + Resources.LOAN_APPLICATIONS.subrequests.DATA_ROOM.subrequests.AUDIT.name}
                  sort={{field: "eventDate", order: "DESC"}} pagination={<PaginationBar/>} perPage={20}>
        <Datagrid bulkActionButtons={false}>
            <DateTimeFieldTZ source="eventDate" label={"Event Date (London)"}/>
            <EnumDictField source="eventType" enum={DataroomAuditEventType} />
            <TextField source="documentName"/>
            <TextField source="userLogin" />
        </Datagrid>

    </List>)

}

export default DataroomAuditList;

DataroomAuditList.propTypes = {
    loanApplication: PropTypes.object.isRequired,
}