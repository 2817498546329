import React from "react";
import {FunctionField, useRecordContext} from "react-admin";
import PropTypes from "prop-types";

import {formatAmount, getValueByPath} from "../../common/utils";
import {DictLoader} from "./DictLoader";

export function FormattedAmountField(props) {
    const {label, options} = props;
    const record = useRecordContext();
    const amount = getValueByPath(record, props.source);
    const currency = getValueByPath(record, props.currencyAttr);

    return (
        <>
            <DictLoader/>
            <FunctionField
                label={label}
                render={() => {
                    return formatAmount(currency, amount, options);
                }}
            />
        </>
    );
}

FormattedAmountField.propTypes = {
    currencyAttr: PropTypes.string,
    source: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.object,
};

export default FormattedAmountField;
