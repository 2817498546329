import {FunctionField} from "ra-ui-materialui";
import React from "react";
import {TextField} from "react-admin";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

function PersonEmailField(props) {

    const {source, verifiedSource, label} = props;

    return (
        <FunctionField label={label} render={(record) => {
            // console.log("record[props.source]",record[props.source])
            return (
                <Grid container direction="row" flexWrap="nowrap" alignItems="center">
                    <Grid item paddingRight={1}>{record[verifiedSource] ? <CheckIcon /> : <CloseIcon />}</Grid>
                    <Grid item><TextField source={source} record={record} /></Grid>
                </Grid>
            )
        }} />

    );
}

export default PersonEmailField;
