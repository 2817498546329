import {FunctionField} from "ra-ui-materialui";
import React from "react";

function DateTimeFieldTZ(props) {

    return (
        <FunctionField label={props.label} render={(record) => {
            // console.log("record[props.source]",record[props.source])
            return record[props.source]
                ? new Date(record[props.source] + (record[props.source].endsWith("Z") ? "" : "Z")).toLocaleString('en-GB', {timeZone: 'Europe/London'}) : ""
        }} />

    );
}

export default DateTimeFieldTZ;
