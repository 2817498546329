import PropTypes from 'prop-types'
import Resources from "../../Resources";
import {ReferenceArrayField, SingleFieldList, FunctionField, RecordContextProvider } from "react-admin";
import Chip from '@mui/material/Chip';

function LegalEntityArrayField(props) {
    return (
        <FunctionField render={(record) => {
            const ids = props.employerIdFieldName ? record[props.source].map((rec) => rec[props.employerIdFieldName]) : record[props.source];
            return (
                <RecordContextProvider value={{participantEmployerIds: ids}}>
                    <ReferenceArrayField source="participantEmployerIds" reference={Resources.LEGAL_ENTITY.name} label={props.label}>
                        <SingleFieldList linkType="show">
                            <FunctionField render={(rec) => {
                                return (<Chip label={`${rec.id}: ${rec.fullCompanyName}`}/>);
                            }} />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </RecordContextProvider>
            )
        }} />
    );
}

LegalEntityArrayField.propTypes = {
  employerIdFieldName: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string.isRequired
}

export default LegalEntityArrayField;