import PropTypes from 'prop-types'
import {useQuery} from "react-query";
import Resources from "../../Resources";
import {useDataProvider, RecordContextProvider, TextField, FunctionField } from "react-admin";
import EnumDictField from "./EnumDictField";
import {PRODUCT_APPLICATION_TYPES} from "../dicts/PartyEnums";

function ProductApplicationField(props) {
    const dataProvider = useDataProvider();
    const { data, isLoading, error } = useQuery(
        ['productApplications', props.partyId],
        () => {
            return dataProvider['doAction'](Resources.LEGAL_ENTITY.name, {
                action: Resources.LEGAL_ENTITY.subrequests.APPLICATIONS.name,
                id: props.partyId,
                method: 'GET'
            });
        }, {enabled: !!props.partyId});
    const applications = data?.data;
    const application = applications?.find((app) => app.id === props.applicationId && app.applicationType === props.applicationType);
    if(!application) {
        return null;
    }
    return (
        <>
            <RecordContextProvider value={application}>
                <EnumDictField enum={PRODUCT_APPLICATION_TYPES} source="applicationType" />
                <FunctionField render={() => " "} />
                <TextField source="name" />
            </RecordContextProvider>
        </>
    );
}

export default ProductApplicationField;

ProductApplicationField.propTypes = {
  applicationId: PropTypes.number.isRequired,
  applicationType: PropTypes.string.isRequired,
  partyId: PropTypes.number.isRequired
}