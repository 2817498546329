import PropTypes from 'prop-types'
import React, {useMemo, useRef, useState} from "react";
import JoditEditor from "jodit-react";
import {useInput, useTheme} from "react-admin";

export const RICH_TEXT_ENTITIES = {
    CALL_REPORT: 'CALL_REPORT',
    CALL_REPORT_TEMPLATES_DICT: 'CALL_REPORT_TEMPLATES_DICT',
}

function RichTextEditor(props) {
    const {setJoditEditorRef} = props;
    const editor = useRef(null);
    const urlsToUploadImage = {
        [RICH_TEXT_ENTITIES.CALL_REPORT]: `/api/v1/callReports/${props.entityId}/documents`
    };

    const FILE_ID_PARAM = "{fileId}"
    const urlsToDownloadImage = {
        [RICH_TEXT_ENTITIES.CALL_REPORT]: `/api/v1/callReports/${props.entityId}/documents/${FILE_ID_PARAM}/content`
    }
    const { onChange, onBlur, label, helperText, ...rest } = props;
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired
    } = useInput({
        onChange,
        onBlur,
        ...rest,
    });
    const [content, setContent] = useState(field?.value || '');
    const [theme, setTheme] = useTheme();

    const urlToUpload = urlsToUploadImage[props.entityType];
    const urlToDownload = urlsToDownloadImage[props.entityType];

    const imgTagName = 'img';
    const aTagName = 'a';

    const config = useMemo( () => {
            return {
                readonly: false,
                theme: theme === 'dark' ? "dark" : "light",
                placeholder: props.placeholder || '',
                className: error ? "jodit-error" : "",

                uploader: !urlToUpload ? null : {
                    url: urlToUpload,  //your upload api url
                    insertImageAsBase64URI: false, //not inster base64
                    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                    filesVariableName: function (t) {
                        return 'documentFile.rawFile';
                    },
                    withCredentials: false,
                    pathVariableName: 'path',
                    format: 'json',
                    method: 'POST',
                    prepareData: function (formdata) {

                        return {...formdata, setId: "internal_docs"};
                    },
                    isSuccess: function (e) {
                        return e.success;
                    },
                    getMessage: function (e) {
                        return e?.data?.messages && Array.isArray(e.data.messages)
                            ? e.data.messages.join('')
                            : '';
                    },
                    process: function (resp) { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                        return resp;
                    },
                    error: function (_this, e) {
                        _this.j.e.fire('errorMessage', e.message, 'error', 4000);
                    },
                    defaultHandlerSuccess: function (resp) { // `this` is the editor.
                        const j = this;
                        if (resp?.data && resp.data.length) {
                            resp.data.forEach((file, index) => { //edetor insertimg function
                                const url = urlToDownload.replace(FILE_ID_PARAM, file.id);
                                if(file.mimeType.startsWith("image")) {
                                    const elm = j.createInside.element(imgTagName);
                                    elm.setAttribute('src', url);
                                    j.s.insertImage(elm, null, j.o.imageDefaultWidth);
                                } else {
                                    const elm = j.createInside.element(aTagName);
                                    elm.setAttribute("href", url);
                                    elm.innerText = file.title;
                                    j.s.insertNode(elm);
                                }


                            });
                        }
                    },
                    defaultHandlerError: function (_this, e) {
                        _this.j.e.fire('errorMessage', e.message);
                    },
                    contentType: function (e) {
                        return (
                            (void 0 === this.jodit.ownerWindow.FormData || 'string' == typeof e) &&
                            'application/x-www-form-urlencoded; charset=UTF-8'
                        );
                    },
                },
            }
        },
        [theme]
    );

    return (
        <>
            <label htmlFor={props.source}>
                <span className={!error ? "jodit-label" : "jodit-error-message"}>{props.label}</span>
                <JoditEditor
                    {...field}
                    ref={editor}
                    value={content}
                    config={config}
                    editorRef={setJoditEditorRef}
                    // tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {field.onChange(newContent)}}
                    {...rest}
                />
                {error && <span className="jodit-error-message">{error.message?.message}</span>}
            </label>
        </>
    );
}

export default RichTextEditor;

RichTextEditor.propTypes = {
  disableImages: PropTypes.bool,
  entityId: PropTypes.any.isRequired,
  entityType: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setJoditEditorRef: PropTypes.func,
  source: PropTypes.string
}