import PropTypes from 'prop-types'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Button, Form, TextInput, FileInput, FileField, useDataProvider, useNotify, useRefresh} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, {useState} from "react";
import {Grid} from "@mui/material";
import Resources from "../../Resources";
import {onError} from "../../common/utils";

const UploadDialog = (props) => {
    const {close, loanApplicationId, open, set, selectedFolderName, selectedFolderId} = props;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = (event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        close();
    }

    const onSuccessDocumentUpload = (doc) => {
        let msg;
        if (doc.filename) {
            msg = `Document "${doc.filename}" successfully uploaded`
        } else {
            if (Array.isArray(doc.data)) {
                if (doc.data.length > 1) {
                    let filenames = doc.data.map(e => e.filename).join(", ");
                    msg = `Documents "${filenames}" successfully uploaded`
                } else if (doc.data.length === 1) {
                    msg = `Document "${doc.data[0].filename}" successfully uploaded`
                }
            }
        }
        notify(msg, {type: 'success', autoHideDuration: 2000});
        refresh();
        close();
    }


    const doUpload = (data) => {
        setIsLoading(true);
        const formData = new FormData();
        data.documentFile.forEach(e => {
            let path = e.rawFile.webkitRelativePath;
            if (!path) {
                path = e.rawFile.path.startsWith("/") ? e.rawFile.path.substring(1) : e.rawFile.path;
            }
            formData.append("documentFile.rawFile", e.rawFile, path);
        })

        if (set.id) {
            formData.append("setId", set.id);
            if (selectedFolderId)
                formData.append("parentId", selectedFolderId);
        }

        if (data.description) {
            formData.append("description", data.description);
        }

        dataProvider['doUpload'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.DOCUMENTS.name,
            id: loanApplicationId,
            method: 'POST',
            body: formData
        }).then(({data}) => {
            setIsLoading(false)
            if (data.success) {
                onSuccessDocumentUpload(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            setIsLoading(false)
            onError(error, notify)
        });
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="md">
            <DialogTitle>Upload document</DialogTitle>
            <Form onSubmit={doUpload}>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            Upload a new file into {selectedFolderName ? "'" + selectedFolderName + "' folder of " : ""} the set '{set.name}'
                        </Grid>
                        <Grid item xs={11}>
                            <FileInput source="documentFile" multiple={true} fullWidth>
                                <FileField source="src" title="rawFile.path" fullWidth/>
                            </FileInput>
                        </Grid>
                        <Grid item xs={11}>
                            <TextInput multiline source="description" title="Description" fullWidth/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} label="Cancel"/>
                    <Button type="submit" disabled={isLoading} label="Add"/>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default UploadDialog;

UploadDialog.propTypes = {
  close: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  selectedFolderId: PropTypes.string,
  selectedFolderName: PropTypes.string,
  set: PropTypes.any.isRequired,
}