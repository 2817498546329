import {Datagrid, DateField, EditButton, ShowButton, TextField} from "react-admin";
import AuthenticatedBulkDeleteAction from "../controls/AuthenticatedBulkDeleteAction";
import * as React from "react";
import EnumDictField from "../controls/EnumDictField";
import {COMMUNICATION_TYPE} from "../dicts/CallReportEnums";
import LegalEntityArrayField from "../controls/LegalEntityArrayField";
import PropTypes from "prop-types";

function CallReportDatagrid(props) {
    const { readOnly, hideShowButton, hideBulkActionsButton } = props
    return (
        <Datagrid data-testid="call-report-list-grid" bulkActionButtons={hideBulkActionsButton ? false : <AuthenticatedBulkDeleteAction />} >
            <TextField source="id"/>
            <DateField source="communicationDate" label={"Communication Date"} />
            <EnumDictField enum={COMMUNICATION_TYPE} source="communicationType"/>
            <LegalEntityArrayField source="contactPersons" employerIdFieldName="participantEmployerId" label="Organisations" />
            <TextField source="createdBy" />
            {!readOnly && <EditButton />}
            {!hideShowButton && <ShowButton />}
        </Datagrid>
    );
}

export default CallReportDatagrid;

CallReportDatagrid.propTypes = {
    readOnly: PropTypes.bool,
    hideShowButton: PropTypes.bool,
    hideBulkActionsButton: PropTypes.bool,
}