import axios from "axios";
import { jwtDecode } from "jwt-decode";

const SESSION_NAME = "session_data";
// let session;

const authProvider = {



    login: ({username, password}) => {

        return new Promise((resolve, reject) => {
            axios("/api/auth/login", {
                method: "POST",
                data: {username, password},
            }).then((response) => {
                storeSession(response.data);
                resolve();
            }).catch((error) => {
                console.log(error);
                reject();
            })
        })
    },
    logout: () => {
        removeSession();
        return new Promise((resolve, reject) => {
            axios("/api/auth/logout", {
                method: "POST",
                data: {},
            }).then((response) => {
                resolve();
            }).catch((err) => {
                reject();
            })
        })
    },
    checkAuth: () => {
        const session = getSession();
        if (session && session.email && checkIfDecodedTokenValid(session)) {
            return Promise.resolve();
        } else {
            return new Promise((resolve, reject) => {
                axios("/api/auth/check", {
                    method: "GET",
                    data: {},
                }).then((response) => {
                    // when token is valid then auth-api doesn't send parsed token so we don't need to rewrite session with an empty object
                    if (response?.data?.email) {
                        storeSession(response.data);
                    }
                    resolve()
                }).catch((error) => {
                    reject();
                })
            })

        }
    },
    checkError: (error) => {
        // console.log("check error", error)
        // console.log("error status", error?.response?.status)
        const status = error?.response?.status;
        if (status === 401 || status === 403) {
            removeSession();
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () => {
        const session = getSession();
        if(session) {
            return Promise.resolve(session.email)
        } else {
            return Promise.reject("Need to relogin");
        }
    },
    getSession: () => {
        return getSession();
    },
    getPermissions: () => {
        const session = getSession();
        if(session) {
            return Promise.resolve(session);
        } else {
            return Promise.reject("recreate session");
        }
    },
};

export function storeSession(session) {
    localStorage.setItem(SESSION_NAME, JSON.stringify(session));
}

export function getSession() {
    try {
        if (localStorage.getItem(SESSION_NAME) != null) {
            return JSON.parse(localStorage.getItem(SESSION_NAME));
        } else {
            return null;
        }
    } catch (e) {
        // error can be thrown of incorrect JSON is saved in local storage
        removeSession();
        return null;
    }
}

export function removeSession() {
    localStorage.removeItem(SESSION_NAME);
}

export function checkIfDecodedTokenValid(decodedToken) {
    if(decodedToken && decodedToken.exp) {
        // console.log("Date.now()", Date.now())
        // console.log("decodedToken.exp * 1000", decodedToken.exp * 1000)
        if(Date.now() < decodedToken.exp * 1000) {
            return true;
        }
    }
    return false;
}

export function decodeToken(token) {
    return jwtDecode(token);
}


export default authProvider;
