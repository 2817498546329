import PropTypes from 'prop-types'
import {AutocompleteInput, ReferenceInput} from "react-admin";
import Resources from "../../Resources";
import React from "react";

function PersonInput(props) {
    return (
        <ReferenceInput source={props.source} enableGetChoices={({name}) => {return name && name.length > 2}} reference={Resources.PERSONS.name} isRequired={props.isRequired}>
            <AutocompleteInput label={props.label} optionValue="id" onChange={props.onChange} isRequired={props.isRequired}
                               filterToQuery={(searchText) => {return {name: searchText}}} fullWidth={props.fullWidth} />
        </ReferenceInput>
    );
}

export default PersonInput;

PersonInput.propTypes = {
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  source: PropTypes.string.isRequired
}