import PropTypes from 'prop-types'
import React from "react";
import {TextInput} from "react-admin";
import {DictLoader} from "./DictLoader";
import {DICTS, MAX_CURRENCY_SCALE} from "../../common/constants";

export function AmountInput(props) {
    const {label, source, name, onChange, disabled, fullWidth, required, currency} = props;

    const fractionDigits =
        DICTS.STORE_CURRENCY_SCALES_MAP && currency
            ? DICTS.STORE_CURRENCY_SCALES_MAP[currency]
            : MAX_CURRENCY_SCALE;

    const regex =
        fractionDigits === 0
            ? /\d+/
            : "^\\d+\\.?\\d{0," + fractionDigits + "}$";

    const onChangeAction = (event) => {
        if (onChange) {
            onChange(event);
        }
    }

    const onParse = (value) => {
        let matches = value.match(regex);
        if (matches && matches.length) {
            return matches[0];
        }

        return value.substring(0, value.length-1);
    }

    return (
        <>
            <DictLoader/>
            <TextInput label={label || "Amount"} source={source || "amountStr"} required={!!required}
                       onChange={onChangeAction} disabled={!!disabled} fullWidth={!!fullWidth}
                       autoComplete="off" name={name}
                       parse={onParse}
            />
        </>
    );
}

AmountInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    source: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    currency: PropTypes.string,
}
