import {SimpleForm, TextInput, maxLength} from "react-admin";
import Grid from "@mui/material/Grid";
import AddressInput from "../controls/AddressInput";

function LegalEntitiesForm(props) {
    const validateForm = (form) => {
        const errors = {};
        if(form.description?.length > 2000) {
            errors.description = "Description must be less or equal 2000 symbols";
        }
        return errors;
    }

    return (
        <SimpleForm validate={validateForm}>
            <Grid container spacing={1} maxWidth="75%">
                <Grid item xs={5}>
                    <TextInput source="fullCompanyName" fullWidth/>
                </Grid>
                <Grid item xs={5}>
                    <TextInput source="registrationNumber" fullWidth/>
                </Grid>
                <AddressInput source="registeredOfficeAddress" />
                <AddressInput source="primaryBusinessAddress" />
                <Grid item xs={12}>
                    <TextInput source="description" fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="website" fullWidth/>
                </Grid>
            </Grid>
        </SimpleForm>
    );
}

export default LegalEntitiesForm;