import {List, Show, Tab, TabbedShowLayout, TopToolbar, useShowController} from "react-admin";
import Resources from "../../Resources";
import PersonDetails from "../entityDetails/PersonDetails";
import * as React from "react";
import PartyPartyRelDatagrid from "../partyPartyRels/PartyPartyRelDatagrid";
import PartyPartyRelCreateButton from "../partyPartyRels/PartyPartyRelCreateButton";
import CallReportListPage from "../callReports/CallReportListPage";

function PersonShowPage() {
    const {record} = useShowController();
    const {id} = record || {};

    function LinkedLegalEntitiesActions(props) {
        return (
            <>
                <TopToolbar>
                    <PartyPartyRelCreateButton fromId={id} />
                </TopToolbar>
            </>
        );
    }

    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Person">
                    <PersonDetails record={record} />
                </Tab>
                <Tab label="Legal Entities">
                    {id &&
                        <List actions={<LinkedLegalEntitiesActions />} empty={<LinkedLegalEntitiesActions />}
                              resource={Resources.PARTY_PARTY_REL.name} filter={{fromId: id}}
                              sort={{field: "id", order: "DESC"}}>
                            <PartyPartyRelDatagrid showToEntity />
                        </List>
                    }
                </Tab>
                <Tab label="Meeting Notes">
                    {id &&
                        <CallReportListPage resource={Resources.CALL_REPORT.name}
                                            filter={{participantId: id}}
                                            hideFilters
                        />
                    }
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export default PersonShowPage;