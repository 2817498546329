import PropTypes from 'prop-types'
import React from "react";
import {ReferenceArrayInput, SelectArrayInput, useChoicesContext} from "react-admin";
import SelectInputWithChoiceContext from "./SelectInputWithChoiceContext";

export default function DictArrayInput(props) {
    return (
        <ReferenceArrayInput name={props.name} link={""} label={props.label} filter={props.filter} source={props.source}
                        sort={{field: "code", order: "ASC"}}
                        reference={props.reference} alwaysOn={props.alwaysOn}>
            <SelectInputWithChoiceContext multipleChoice={true}
                label={props.label} onChange={props.onChange} optionValue="code"
                optionText={props.optionText ? props.optionText : "name"}
                fullWidth={props.fullWidth} required={props.required} disabled={props.disabled}
            />
        </ReferenceArrayInput>
    );
}

DictArrayInput.propTypes = {
    filter: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    reference: PropTypes.string.isRequired,
    required: PropTypes.bool,
    source: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    alwaysOn: PropTypes.bool,
    optionText: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
}
