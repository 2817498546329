import {ReferenceField, TextField, FunctionField} from "react-admin";
import * as React from "react";
import PropTypes from "prop-types";

const EnumDictField = (props) => {
    return (
        <>
            <FunctionField label={props.label} render={(record) => props.enum?.[record?.[props.source]]?.label} />
        </>
    );
}

EnumDictField.propTypes = {
    enum: PropTypes.object.isRequired,
    label: PropTypes.string,
    source: PropTypes.string.isRequired,
}

export default EnumDictField;
