import React, {useState} from "react";
import Resources from "../../Resources";
import {
    Datagrid,
    DateInput,
    FunctionField,
    List,
    ReferenceField,
    TextField,
    TextInput,
    useRecordContext
} from "react-admin";
import DateTimeFieldTZ from "../controls/DateFieldTZ";
import EnumDictField from "../controls/EnumDictField";
import {AuditEventType, AuditObjectType} from "../dicts/AuditEventType";
import EnumDictInput from "../controls/EnumDictInput";
import {formatAmount} from "../../common/utils";
import CodeIcon from "@mui/icons-material/Code";
import {ViewDialog} from "../controls/ViewDialog";

const LinkToObject = (props) => {
    const {label} = props
    const auditRecord = useRecordContext()
    // console.log("auditRecord", auditRecord)
    if (!auditRecord || !auditRecord.objectId) return null;

    function getDisplayText(record) {
        // console.log("record", record)
        if (auditRecord.objectType === AuditObjectType.LOAN_APPLICATION.code
            || auditRecord.objectType === AuditObjectType.LOAN_APPLICATION_DOCUMENT.code) {
            return  formatAmount(record.loanCurrency, record.loanAmount) + ' (' + record.borrowerCompanyFullName + ')';
            // console.log("record for", AuditObjectType[auditRecord.objectType], record, txt)
        } else {
            return record.displayName;
        }
    }
    const defineReference = () => {
        return AuditObjectType[auditRecord.objectType]?.reference;
    }
    function defineSource() {
        return AuditObjectType[auditRecord.objectType]?.source || "objectId"
    }
    const defineLink = (record, reference) => {
        let auditObjectTypeElement = AuditObjectType[auditRecord.objectType];
        let showLink = auditObjectTypeElement?.showLink;
        if (showLink) {
            showLink = showLink.replace("${refId}", record.refId)
            showLink = showLink.replace("${id}", record.id)
        }
        return showLink ? showLink : reference
    }
    const defineMeta = () => {
        let getManyResource = AuditObjectType[auditRecord.objectType]?.getManyResource;
        if (getManyResource) {
            return {getManyResource}
        }
        return {}
    }

    const meta = defineMeta()
    const reference = defineReference()
    const source = defineSource()

    return (
        <ReferenceField source={source} reference={reference}
                        link={defineLink} label={label}
                        queryOptions={{meta}}>
            <>
                <FunctionField render={getDisplayText}/>
            </>
        </ReferenceField>
    )
}

const AuditFilters = [
    <TextInput source="userLoginIgnoreCase" label="User Login" alwaysOn />,
    <DateInput source="eventDateAfter" alwaysOn />,
    <DateInput source="eventDateBefore" alwaysOn />,
    <EnumDictInput enum={AuditEventType} source="eventType" label="Event Type" alwaysOn/>,
    <EnumDictInput enum={AuditObjectType} source="objectType" label="Object Type" alwaysOn/>,
    <TextInput source="objectId" alwaysOn />,
];


function AuditLogList(props) {
    const [paramsOpened, setParamsOpened] = useState(false);
    const [paramsContent, setParamsContent] = useState(null);

    return (
        <List filters={AuditFilters} disableSyncWithLocation={true} resource={Resources.AUDIT.name}
              sort={{field: "eventDate", order: "DESC"}}>
            <ViewDialog open={paramsOpened} onClose={() => setParamsOpened(false)} content={paramsContent}/>
            <Datagrid bulkActionButtons={false}>
                <DateTimeFieldTZ source="eventDate" label={"Event Date (London)"}/>
                <EnumDictField source="eventType" enum={AuditEventType}/>
                <LinkToObject label="Object"/>
                <TextField source="userLogin"/>
                <FunctionField label="Params" render={(record) => {
                    const p = JSON.stringify(record.params);
                    return p ? <span title={p}><CodeIcon fontSize="small" onClick={() => {setParamsContent(p); setParamsOpened(true)}}/></span> : "";
                }}/>
            </Datagrid>
        </List>
    )
}

export default AuditLogList;

AuditLogList.propTypes = {
}