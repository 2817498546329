import PropTypes from 'prop-types'
import React from "react";
import getCountries from "../../common/countries";
import EnumDictInput from "./EnumDictInput";

function CountryInput(props) {
    const countries = getCountries();
    const countriesEnum = countries.reduce((acc, val) => ({...acc, [val[0]]:{code:val[0], label:val[1]}}), {});
    return (
        <>
            <EnumDictInput multipleChoice={props.multipleChoice} enum={countriesEnum} label={props.label}
                           source={props.source} required={props.required} fullWidth={props.fullWidth}
                           disabled={props.disabled}/>
        </>
    );
}

export default CountryInput;

CountryInput.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.bool,
  multipleChoice: PropTypes.bool,
  required: PropTypes.bool,
  source: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}