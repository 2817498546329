import PropTypes from 'prop-types'
import React from "react";
import {usePermissions} from "react-admin";

export function CheckPermission(props) {
    const { permissions } = usePermissions();
    if(!permissions || !permissions['cognito:groups']?.includes(props.role)) {
        return null;
    }
    return (
        <>{props.children}</>
    );
}

CheckPermission.propTypes = {
  children: PropTypes.any.isRequired,
  role: PropTypes.string.isRequired
}
