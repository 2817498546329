import {
    Show
} from "react-admin";
import * as React from "react";
import CallReportShowForm from "./CallReportShowForm";

function CallReportShowPage(props) {
    return (
        <Show>
            <CallReportShowForm />
        </Show>
    );
}

export default CallReportShowPage;