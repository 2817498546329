import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Button} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import DialogContentText from "@mui/material/DialogContentText";

export const ViewDialog = (props) => {
    const {title, contentText, content, onClose, open} = props;
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {contentText &&
                    <DialogContentText>
                        {contentText}
                    </DialogContentText>
                }
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} label="Close"/>
            </DialogActions>
        </Dialog>
    );
}