import PropTypes from 'prop-types'
import {Button, Confirm, useDataProvider, useNotify, useRefresh} from "react-admin";
import Resources from "../../Resources";
import * as React from "react";
import {useState} from "react";
import UploadDialog from "../controls/UploadDialog";
import {onError, toLondonDateTime} from "../../common/utils";
import {AdminPortalRoles} from "../dicts/Security";
import {CheckPermission} from "../security/CheckPermission";
import {Tree} from "react-arborist";
import {
    DATA_ROOM_PROTECTED_SET,
    DATA_ROOM_SET_ROOT,
    DataRoomItemType,
    LoanApplicationStatus
} from "../dicts/LoanApplicationEnum";
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DataRoomManageFolderDialog, {MANAGE_FOLDER_ACTION} from "../loan_applications/DataRoomManageFolderDialog";
import {useQuery} from 'react-query';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import TabIcon from '@mui/icons-material/Tab';
import TabUnselectedIcon from '@mui/icons-material/TabUnselected';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {Grid} from '@mui/material';
import ReplaceBriefDescriptionDialog from "../loan_applications/ReplaceBriefDescriptionDialog";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmEx from "../controls/ConfirmEx";

function ReplaceBriefDescriptionButton(props) {
    const {loanApplication} = props;
    const [replaceDialogOpened, setReplaceDialogOpened] = useState(false)
    const openReplaceDialog = () => {
        setReplaceDialogOpened(true);
    }
    const closeReplaceDialog = () => {
        setReplaceDialogOpened(false)
    }
    return (
        <>
            {replaceDialogOpened &&
                <ReplaceBriefDescriptionDialog
                    close={closeReplaceDialog}
                    loanApplication={loanApplication}
                    open={replaceDialogOpened}/>
            }
            <Button onClick={openReplaceDialog} label="Replace One Pager">
                <DescriptionIcon/>
            </Button>
        </>
    )
}

function LoanApplicationDocuments(props) {
    const {loanApplication} = props;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const loanApplicationId = loanApplication?.id;
    const loanApplicationStatus = loanApplication.statusCode;
    const isNotActive =
        loanApplicationStatus === LoanApplicationStatus.REJECTED.code
        || loanApplicationStatus === LoanApplicationStatus.FUNDED.code
        || loanApplicationStatus === LoanApplicationStatus.INACTIVE.code;

    const { data, isLoading, error } = useQuery(
        ['loanApplicationDataRoom', 'getOne', { id: loanApplicationId }],
        () => {
            return dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
                action: Resources.LOAN_APPLICATIONS.subrequests.DATA_ROOM.name,
                id: loanApplicationId,
                method: 'GET'
            });
    });
    const dataRoom = data?.data?.data?.sets;
    // const dataRoomHook = useGetOne(dataRoomResource, {}, {enabled: !!loanApplicationId});

    const [isUploadDialogOpened, setIsUploadDialogOpened] = useState(false);
    const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
    const [isApproveDeleteDialogOpened, setIsApproveDeleteDialogOpened] = useState(false);
    const [isDeleteFolderDialogOpened, setIsDeleteFolderDialogOpened] = useState(false);
    const [docToDelete, setDocToDelete] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState(null);
    const [isApproveDialogOpened, setIsApproveDialogOpened] = useState(false);
    const [docToApprove, setDocToApprove] = useState(false);

    const [contextMenu, setContextMenu] = useState(null);
    const [nodeInContextMenu, setNodeInContextMenu] = useState(null);
    const [isManageFolderDialogOpened, setIsManageFolderDialogOpened] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folderAction, setFolderAction] = useState("");
    const [dataRoomTree, setDataRoomTree] = useState(null);
    const [needToSetPermissions, setNeedToSetPermissions] = useState(false)

    const addChildrenForEmptyFolders = (node) => {
        if (node) {
            if (node.itemType === DataRoomItemType.FOLDER || node.itemType === DataRoomItemType.SET) {
                if (!node.children) {
                    node.children = [];
                } else if (node.children.length > 0) {
                    node.children.forEach((item) => addChildrenForEmptyFolders(item));
                }
            }
        }
    }

    if (dataRoom && dataRoom.length > 0) {
        dataRoom.forEach((set) => addChildrenForEmptyFolders(set));
    }

    const openUploadDialog = () => {
        setIsUploadDialogOpened(true);
    };
    const closeUploadDialog = () => {
        setIsUploadDialogOpened(false);
    };
    const openDeleteDialog = (value) => {
        // console.log("inside openDeleteDialog", value)
        setIsDeleteDialogOpened(true);
        setDocToDelete(value);
    };
    const closeDeleteDialog = (value) => {
        setIsDeleteDialogOpened(false);
        setDocToDelete(null);
    };
    const openApproveDeleteDialog = (value) => {
        setIsApproveDeleteDialogOpened(true);
        setDocToDelete(value);
    };
    const closeApproveDeleteDialog = (value) => {
        setIsApproveDeleteDialogOpened(false);
        setDocToDelete(null);
    };
    const openDeleteFolderDialog = (value) => {
        // console.log("inside openDeleteDialog", value)
        setIsDeleteFolderDialogOpened(true);
        setFolderToDelete(value);
    };
    const closeDeleteFolderDialog = () => {
        setIsDeleteFolderDialogOpened(false);
        setFolderToDelete(null);
    };
    const openApproveDialog = (value) => {
        // console.log("inside openDeleteDialog", value)
        setIsApproveDialogOpened(true);
        setDocToApprove(value);
    };
    const closeApproveDialog = (value) => {
        setIsApproveDialogOpened(false);
        setDocToApprove(null);
    };
    const doDelete = () => {
        if (!docToDelete) {
            console.log("Nothing selected to delete")
            return;
        }
        dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
            id: loanApplication.id,
            action: `${Resources.LOAN_APPLICATIONS.actions.DOCUMENTS.name}/${docToDelete.id}`,
            method: 'DELETE'
        }).then(onSuccessDocumentDelete).catch(error => onError(error, notify));
    }
    const doApproveDelete = () => {
        doApproveOrRejectDeleteRequest(
            Resources.LOAN_APPLICATIONS.actions.DOCUMENTS.subrequests.APPROVE_DELETE_REQUEST.name,
            onSuccessDocumentDelete
        )
    }
    const doRejectDelete = () => {
        doApproveOrRejectDeleteRequest(
            Resources.LOAN_APPLICATIONS.actions.DOCUMENTS.subrequests.REJECT_DELETE_REQUEST.name,
            onRejectDocumentDeleteRequest
        )
    }
    const doApproveOrRejectDeleteRequest = (subAction, successFn) => {
        if (!docToDelete) {
            console.log("Nothing selected to delete")
            return;
        }
        const action = `${Resources.LOAN_APPLICATIONS.actions.DOCUMENTS.name}/${docToDelete.id}/${subAction}`;
        dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
            id: loanApplication.id,
            action,
            method: 'PUT'
        }).then(successFn).catch(error => onError(error, notify));
    }

    const doApprove = () => {
        if (!docToApprove) {
            console.log("Nothing selected to approve")
            return;
        }
        dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
            id: loanApplication.id,
            action: `${Resources.LOAN_APPLICATIONS.actions.DOCUMENTS.name}/${docToApprove.id}/approve`,
            method: 'PUT'
        }).then(onSuccessDocumentApprove).catch(error => onError(error, notify));
    }

    const isNodeASet = (node) => {
        return node && node?.data?.itemType === DataRoomItemType.SET;
    }

    const nodeInContextMenuIsSet = () => {
        return isNodeASet(nodeInContextMenu);
    }

    const nodeInContextMenuIsProtectedSet = () => {
        return isNodeASet(nodeInContextMenu) && nodeInContextMenu?.data?.id === DATA_ROOM_PROTECTED_SET;
    }

    const isNodeAFolder = (node) => {
        return node && node?.data?.itemType === DataRoomItemType.FOLDER;
    }

    const nodeInContextMenuIsFolder = () => {
        return isNodeAFolder(nodeInContextMenu);
    }

    const nodeInContextMenuIsSecured = () => {
        return isNodeAFolder(nodeInContextMenu) && !!nodeInContextMenu?.data?.secured;
    }

    const nodeInContextMenuIsRequestedToDelete = () => {
        return isNodeADoc(nodeInContextMenu) && !!nodeInContextMenu?.data?.deletedDate;
    }

    const isNodeADoc = (node) => {
        return node && node?.data?.itemType === DataRoomItemType.LOAN_APPLICATION_DOCUMENT;
    }

    const nodeInContextMenuIsDoc = () => {
        return isNodeADoc(nodeInContextMenu);
    }

    const nodeInContextMenuIsEmpty = () => {
        return nodeInContextMenu?.data?.children?.length === 0;
    }

    const nodeInContextMenuIsHidden = () => {
        return !!nodeInContextMenu?.data?.hidden;
    }

    const linkDocument = (data, setId, folderId) => {
        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: `dataRoom/sets/${setId}/folders/${folderId}/documents`,
            id: loanApplication.id,
            method: 'POST',
            body: data,
        }).then(({data}) => {
            if (data.success) {
                refresh();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => onError(error, notify));
    }

    const deleteFolder = (set, folderId) => {
        dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
            action: `dataRoom/sets/${set.id}/folders/${folderId}`,
            id: loanApplication.id,
            method: 'DELETE',
        }).then(({data}) => {
            if (data.success) {
                refresh();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => onError(error, notify));
    }

    const moveFolder = (set, folderId, newSetId, newParentFolderId) => {
        // console.log('onCapitalSourcingSubmit', data)
        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: `dataRoom/sets/${set.id}/folders/${folderId}`,
            id: loanApplicationId,
            method: "PUT",
            body: {setId: newSetId, parentId: newParentFolderId},
        }).then(({data}) => {
            if (data.success) {
                refresh();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
        });
    };

    const onRejectDocumentDeleteRequest = () => {
        notify(`Document ${docToDelete.name} was successfully restored`, {type: 'success', autoHideDuration: 2000});
        setDocToDelete(null);
        refresh();
    }

    const onSuccessDocumentDelete = () => {
        notify(`Document ${docToDelete.name} successfully deleted`, {type: 'success', autoHideDuration: 2000});
        setDocToDelete(null);
        refresh();
    }

    const onSuccessDocumentApprove = () => {
        notify(`Document ${docToApprove.name} successfully approved`, {type: 'success', autoHideDuration: 2000});
        setDocToApprove(null);
        refresh();
    }

    const handleDocContextMenu = (event, node) => {
        event.preventDefault();
        setNodeInContextMenu(node);
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const openManageFolderDialog = () => {
        setIsManageFolderDialogOpened(true);
    }

    const closeManageFolderDialog = () => {
        setIsManageFolderDialogOpened(false);
    }

    const findParentSet = (node) => {
        if(node.data.itemType === DataRoomItemType.SET) {
            return node.data;
        } else {
            return findParentSet(node.parent);
        }
    }

    function buildFolderFromContext() {
        const parentFolder = {set: findParentSet(nodeInContextMenu)};
        if (nodeInContextMenuIsFolder()) {
            parentFolder.id = nodeInContextMenu.data.id;
            parentFolder.name = nodeInContextMenu.data.name
            parentFolder.secured = nodeInContextMenuIsSecured()
            parentFolder.acl = nodeInContextMenu.data.acl
        }
        return parentFolder;
    }

    const onUploadDocument = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsFolder() || nodeInContextMenuIsSet()) {
            const folder = buildFolderFromContext();
            setSelectedFolder(folder);
            openUploadDialog();
        }
    }

    const onAddFolder = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsFolder() || nodeInContextMenuIsSet()) {
            setNeedToSetPermissions(nodeInContextMenuIsProtectedSet())
            setFolderAction(MANAGE_FOLDER_ACTION.CREATE)
            const folder = buildFolderFromContext();
            setSelectedFolder(folder);
            openManageFolderDialog();
        }
    }

    const onRenameFolder = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsFolder()) {
            setFolderAction(MANAGE_FOLDER_ACTION.UPDATE)
            setNeedToSetPermissions(nodeInContextMenuIsSecured())
            const folder = buildFolderFromContext();
            setSelectedFolder(folder)
            openManageFolderDialog();
        }
    }

    const onDeleteFolder = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsFolder() && nodeInContextMenuIsEmpty()) {
            openDeleteFolderDialog();
            setFolderToDelete(nodeInContextMenu);
        }
    }

    const onDeleteFolderRecursively = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsFolder()) {
            openDeleteFolderDialog();
            setFolderToDelete(nodeInContextMenu);
        }
    }

    const onDeleteDoc = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsDoc()) {
            openDeleteDialog(nodeInContextMenu.data);
        }
    }

    const onApproveDeleteDocRequest = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsRequestedToDelete()) {
            openApproveDeleteDialog(nodeInContextMenu.data);
        }
    }

    const onApproveDoc = () => {
        handleCloseContextMenu();
        if(nodeInContextMenuIsDoc()) {
            openApproveDialog(nodeInContextMenu.data);
        }
    }

    const onMoveNode = (event) => {
        if(!event.parentNode) {
            return false;
        }
        const newParentNode = event.parentNode;
        if(isNodeAFolder(newParentNode) || isNodeASet(newParentNode)) {
            const dragId = event.dragIds[0];
            const dragNode = event.dragNodes[0];
            const newParentFolderId = event.parentId;
            const newSet = findParentSet(newParentNode);
            if(isNodeADoc(dragNode)) {
                linkDocument({id: dragId}, newSet.id, isNodeASet(newParentNode) ? DATA_ROOM_SET_ROOT : newParentFolderId)
                return true;
            } else {
                const previousSet = findParentSet(dragNode);
                moveFolder(previousSet, dragId,  newSet.id, isNodeASet(newParentNode) ? null : newParentFolderId)
                return true;
            }
        } else {
            return false;
        }
    }

    function TreeNode({ node, style, dragHandle }) {
        return (
            <div className="arborist-tree" style={style} ref={dragHandle} onClick={() => node.toggle()} onContextMenu={(e) => handleDocContextMenu(e, node)}>
                <span className="node-icon">
                    {node.data.itemType === DataRoomItemType.SET && <>
                        {node.isOpen && <TabIcon />}
                        {node.isClosed && <TabUnselectedIcon />}
                    </>}
                    {node.data.itemType === DataRoomItemType.FOLDER && <>
                        {node.isOpen && <FolderOpenIcon />}
                        {node.isClosed && <FolderIcon />}
                    </>}
                    {node.data.itemType === DataRoomItemType.LOAN_APPLICATION_DOCUMENT && <DescriptionIcon />}
                </span>

                <span className={`text hidden-${node.data.hidden} deleted-${!!node.data.deletedDate}`}
                      title={node.data.deletedDate ? `Requested by Borrower to delete at ${toLondonDateTime(node.data.deletedDate)}` : ""}>
                    {node.data.itemType !== DataRoomItemType.LOAN_APPLICATION_DOCUMENT && node.data.name}
                    {node.data.itemType === DataRoomItemType.LOAN_APPLICATION_DOCUMENT && <a href={`/api/v1/${node.data.link}`}>{node.data.name}</a>}
                    {node.data.itemType === DataRoomItemType.LOAN_APPLICATION_DOCUMENT && node.data.hidden && <VisibilityOffIcon className="hidden-icon"/>}
                    {node.data.itemType === DataRoomItemType.LOAN_APPLICATION_DOCUMENT && node.data.deletedDate && <DeleteForeverIcon className="delete-icon"/>}
                </span>

            </div>
        );
    }

    function doDeleteFolder() {
        if (folderToDelete)
            deleteFolder(findParentSet(folderToDelete), folderToDelete.data.id);
        closeDeleteFolderDialog();
    }

    return (
        <div data-testid="docs-tree-container" >
            {isDeleteDialogOpened && docToDelete && <Confirm
                isOpen={isDeleteDialogOpened}
                title="Delete document"
                content={`Are you sure you want to delete document ${docToDelete.name}?`}
                onConfirm={doDelete}
                onClose={closeDeleteDialog}
            />}
            {isApproveDeleteDialogOpened && docToDelete && <ConfirmEx
                isOpen={isApproveDeleteDialogOpened}
                title="Approve Delete document request"
                content={`Are you sure you want to delete document ${docToDelete.name}?`}
                labelYes="Approve Request"
                labelNo="Reject Request"
                labelCancel="Close"
                onClickYes={doApproveDelete}
                onClickNo={doRejectDelete}
                onClose={closeApproveDeleteDialog}
                onCancel={closeApproveDeleteDialog}
            />}
            {isDeleteFolderDialogOpened && folderToDelete && <Confirm
                isOpen={isDeleteFolderDialogOpened}
                title="Delete folder"
                content={`Are you sure you want to delete folder ${folderToDelete.data.name}?`}
                onConfirm={doDeleteFolder}
                onClose={closeDeleteFolderDialog}
            />}
            {isApproveDialogOpened && docToApprove && <Confirm
                isOpen={isApproveDialogOpened}
                title="Approve document"
                content={`Are you sure you want to approve document ${docToApprove.name}?`}
                onConfirm={doApprove}
                onClose={closeApproveDialog}
            />}
            {isUploadDialogOpened && <UploadDialog open={isUploadDialogOpened} close={closeUploadDialog}
                                                   set={selectedFolder?.set}
                                                   selectedFolderId={selectedFolder?.id}
                                                   selectedFolderName={selectedFolder?.name}
                                                   loanApplicationId={loanApplicationId}
            />}
            {!isLoading && <>
                <Grid container spacing={1} className="tree-toolbar">
                    <Grid item xs={2}>
                        <Button data-testid="docs-tree-expand-all-btn" onClick={() => dataRoomTree.openAll()} label="Expand All">
                            <ExpandMoreOutlinedIcon/>
                        </Button>
                        <Button data-testid="docs-tree-collapse-all-btn" onClick={() => dataRoomTree.closeAll()} label="Collapse All">
                            <ExpandLessOutlinedIcon/>
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        {loanApplication.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code
                            && <ReplaceBriefDescriptionButton loanApplication={loanApplication}/>}
                    </Grid>
                </Grid>

                <Tree data={dataRoom}
                      disableEdit={true}
                      onCreate={() => null}
                      onMove={onMoveNode}
                      openByDefault={false}
                      rowHeight={28}
                      width={1000}
                      height={1000}
                      ref={(tree) => setDataRoomTree(tree)}
                      disableMultiSelection={true}
                      disableDrop={(node) => {return node.itemType === DataRoomItemType.LOAN_APPLICATION_DOCUMENT}}
                      disableDrag={(node) => {return node.itemType === DataRoomItemType.SET}}
                >
                    {TreeNode}
                </Tree>
            </>}
            {nodeInContextMenu && !isNotActive && <Menu
                open={contextMenu !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                {(nodeInContextMenuIsSet() || nodeInContextMenuIsFolder()) && <MenuItem onClick={onAddFolder}>Add Folder</MenuItem>}
                {nodeInContextMenuIsFolder() && !nodeInContextMenuIsSecured() && <MenuItem onClick={onRenameFolder}>Rename</MenuItem>}
                {nodeInContextMenuIsFolder() && nodeInContextMenuIsSecured() && <MenuItem onClick={onRenameFolder}>Rename or change access</MenuItem>}
                {nodeInContextMenuIsFolder() && nodeInContextMenuIsEmpty() && <MenuItem onClick={onDeleteFolder}>Delete</MenuItem>}
                {nodeInContextMenuIsFolder() && !nodeInContextMenuIsEmpty() && <CheckPermission role={AdminPortalRoles.BACKOFFICE_APPROVER}>
                    <MenuItem onClick={onDeleteFolderRecursively}>Delete recursively</MenuItem>
                </CheckPermission>}
                {(nodeInContextMenuIsSet() || nodeInContextMenuIsFolder()) && <MenuItem onClick={onUploadDocument}>Upload Doc</MenuItem>}
                {nodeInContextMenuIsDoc() && !nodeInContextMenuIsRequestedToDelete() && <CheckPermission role={AdminPortalRoles.BACKOFFICE_APPROVER}>
                    <MenuItem onClick={onDeleteDoc}>Delete</MenuItem>
                </CheckPermission>}
                {nodeInContextMenuIsRequestedToDelete() && <CheckPermission role={AdminPortalRoles.BACKOFFICE_APPROVER}>
                    <MenuItem onClick={onApproveDeleteDocRequest}>Approve Borrower request to delete</MenuItem>
                </CheckPermission>}
                {nodeInContextMenuIsDoc() && nodeInContextMenuIsHidden() && <CheckPermission role={AdminPortalRoles.BACKOFFICE_APPROVER}>
                    <MenuItem onClick={onApproveDoc}>Approve</MenuItem>
                </CheckPermission>}
            </Menu>}
            {isManageFolderDialogOpened && <DataRoomManageFolderDialog close={closeManageFolderDialog} isOpened={isManageFolderDialogOpened}
                                        loanApplicationId={loanApplication.id}
                                        action={folderAction}
                                        setId={selectedFolder?.set.id}
                                        selectedFolderId={selectedFolder?.id}
                                        selectedFolderName={selectedFolder?.name}
                                        isProtected={needToSetPermissions}
                                        acceptedCreditors={selectedFolder?.acl}
            /> }
        </div>
    );
}

export default LoanApplicationDocuments;

LoanApplicationDocuments.propTypes = {
  loanApplication: PropTypes.object.isRequired
}