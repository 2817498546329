import PropTypes from 'prop-types'
import React from "react";

function LoanApplicationTitle(props) {
    const {loanApplication} = props;
    return (
        <>{loanApplication.loanAmount} {loanApplication.currency} / {loanApplication.rawdata.fullCompanyName}</>
    );
}

export default LoanApplicationTitle;

LoanApplicationTitle.propTypes = {
  loanApplication: PropTypes.object.isRequired
}