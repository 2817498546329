import PropTypes from 'prop-types'
import {AutocompleteInput, ReferenceInput} from "react-admin";
import Resources from "../../Resources";
import React from "react";

function UserLoginInput(props) {

    const onChange = (v) => {
        if (props.onChange) {
            props.onChange(v, props.valueAttr ? v[props.valueAttr] : undefined)
        }
    }
    return (
        <ReferenceInput source={props.source} enableGetChoices={({name}) => {return name && name.length > 2}}
                        reference={Resources.USERS.name} isRequired={props.isRequired}>
            <AutocompleteInput label={props.label}
                               optionValue="id"
                               optionText={(v) => `${v.id}: ${v.firstName} ${v.lastName} (${v.userLogin})`}
                               onChange={onChange}
                               isRequired={props.isRequired}
                               filterToQuery={(searchText) => {return {filter: searchText}}}
                               fullWidth={props.fullWidth} />
        </ReferenceInput>
    );
}

export default UserLoginInput;

UserLoginInput.propTypes = {
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  source: PropTypes.string.isRequired,
  valueAttr: PropTypes.string,
}